import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function PasswordChangeDialog(props: Props) {
  const {
    open, onClose,
  } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const onCurrentPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };
  const [newPassword, setNewPassword] = useState('');
  const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };
  const [newPasswordConfirm, setNewPasswordConfrim] = useState('');
  const onNewPasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordConfrim(e.target.value);
  };

  useEffect(() => {
    if (!open) {
      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfrim('');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2" flex={1} textAlign="left">
          비밀번호변경
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={0.5} marginBottom={2}>
          <Typography variant="caption">현재 비밀번호</Typography>
          <TextField
            size="small"
            placeholder="비밀번호를 입력해주세요."
            value={currentPassword}
            onChange={onCurrentPasswordChange}
          />
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="caption">새 비밀번호</Typography>
          <TextField
            size="small"
            placeholder="새 비밀번호를 입력해주세요."
            value={newPassword}
            onChange={onNewPasswordChange}
          />
          <TextField
            size="small"
            placeholder="새 비밀번호를 다시 한번 입력해주세요."
            value={newPasswordConfirm}
            onChange={onNewPasswordConfirmChange}
          />
          <Typography variant="caption">
            영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button variant="contained">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Button, Card, CardContent, CardHeader, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext';
import { MainLayout } from '../../common/style';
import config from '../../config';
import { setCookie } from '../../util/Cookie';

interface OAuthButtonProps {
  provider: string;
  children: ReactNode;
  onClick: (provider: string) => void;
}

function OAuthButton({ provider, children, onClick }: OAuthButtonProps) {
  return (
    <Button
      onClick={() => onClick(provider)}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
      }}
    >
      {children}
    </Button>
  );
}

export default function LoginView() {
  const {
    customer,
    setInitialized,
  } = useContext(AppContext);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (customer) {
      navigate('/home');
    }
  }, [customer, navigate]);

  // const [showErrorMessage, setShowErrorMessage] = useState<string>();
  // const [email, setEmail] = useState('');
  // const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(event.target.value);
  //
  //   if (showErrorMessage) {
  //     setShowErrorMessage(undefined);
  //   }
  // };
  //
  // const [password, setPassword] = useState('');
  // const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPassword(event.target.value);
  //   if (showErrorMessage) {
  //     setShowErrorMessage(undefined);
  //   }
  // };
  // const handleLogin = async () => {
  //   try {
  //     const input = await object()
  //       .shape({
  //         email: string().email().required(),
  //         password: string().min(8).max(24).required(),
  //       })
  //       .validate({
  //         email,
  //         password,
  //       });
  //
  //     const res = await login(input.email, input.password);
  //     setToken(res.token);
  //     setCustomer(res.customer);
  //   } catch (error) {
  //     if (error instanceof ServerError) {
  //       setShowErrorMessage('이메일 또는 비밀번호가 일치하지 않습니다');
  //     } else {
  //       setShowErrorMessage('이메일, 비밀번호의 형식을 확인하세요.');
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const publicId = params.get('publicId');

      if (publicId) {
        try {
          setCookie('uid', publicId);
          setInitialized(true);
        } catch (error) {
          // 에러 처리 로직 (예: 에러 페이지로 리다이렉트)
          navigate('/error', { state: { error: 'Failed to fetch user data' } });
        }
      }
    };

    fetchData();
  }, [navigate, setInitialized]);

  const handleOAuthLogin = (provider: string) => {
    window.location.href = `${config.apiHost}/oauth2/authorization/${provider}`;
  };

  return (
    <MainLayout style={{ background: theme.palette.grey[50] }}>
      <Card sx={{ marginTop: '80px', padding: '14px 20px' }}>
        <CardHeader title={<Typography variant="h1">로그인</Typography>} />
        <CardContent>
          <Stack spacing={1} width={{ sm: 480, xs: '100%' }}>
            {/* <Typography variant="body2" align="left" paddingTop={1}> */}
            {/*   이메일 */}
            {/* </Typography> */}
            {/* <TextField fullWidth size="small" type="email" value={email} onChange={handleEmailChange} /> */}
            {/* <Typography variant="body2" align="left" paddingTop={1}> */}
            {/*   비밀번호 */}
            {/* </Typography> */}
            {/* <TextField fullWidth type="password" size="small" value={password} onChange={handlePasswordChange} /> */}
            {/* <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between"> */}
            {/*   <Typography color="error" variant="body2"> */}
            {/*     {showErrorMessage ?? ''} */}
            {/*   </Typography> */}
            {/*   <Button variant="text" href="/password/reset"> */}
            {/*     <Typography variant="body2" color={theme.palette.grey[500]} sx={{ padding: 0, textDecoration: 'underline' }}> */}
            {/*       비밀번호 찾기 */}
            {/*     </Typography> */}
            {/*   </Button> */}
            {/* </Stack> */}

            {/* <Stack width="100%" alignItems="center" paddingTop={3}> */}
            {/*   <Button variant="contained" fullWidth onClick={handleLogin}> */}
            {/*     로그인 */}
            {/*   </Button> */}
            {/* </Stack> */}

            {/* <Stack width="100%" alignItems="center"> */}
            {/*   <Button variant="text" fullWidth href="/registration"> */}
            {/*     <Typography variant="body2" sx={{ padding: 0, textDecoration: 'underline' }}> */}
            {/*       회원가입 */}
            {/*     </Typography> */}
            {/*   </Button> */}
            {/* </Stack> */}
            <OAuthButton provider="kakao" onClick={handleOAuthLogin}>
              <img src={`${config.imageAssetUrl}/assets/btn_kakao_login.png`} alt="Kakao Login" width={200} />
            </OAuthButton>

            <OAuthButton provider="naver" onClick={handleOAuthLogin}>
              <img src={`${config.imageAssetUrl}/assets/btn_naver_login.png`} alt="Naver Login" width={200} />
            </OAuthButton>
          </Stack>
        </CardContent>
      </Card>
    </MainLayout>
  );
}

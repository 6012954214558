import { Button, Checkbox, Fade, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { boolean, object, string } from 'yup';

import SuccessErrorModal from '../../common/modal/success-error-modal';
import { ContentLayout } from '../../common/style';
import { RequestStatus } from '../../models';

export default function InquiryPage() {
  const [email, setEmail] = useState<string>();
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [title, setTitle] = useState<string>();
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const [description, setDescription] = useState<string>();
  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };
  const [agreed, setAgreed] = useState(false);
  const onAgreeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const [errMsg, setErrMsg] = useState<string | undefined>();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const resetInput = () => {
    setTitle('');
    setEmail('');
    setDescription('');
    setAgreed(false);
  };

  useEffect(() => {
    if (requestStatus === RequestStatus.SUCCESS) {
      setModalTitle('문의 접수완료');
      setModalDescription('지원 스태프가 영업일 기준 3일 이내 가능한 빨리 답변 드릴 수 있도록 노력하겠습니다.');
      setIsSuccess(true);
      resetInput();
    } else if (requestStatus === RequestStatus.FAIL) {
      setModalTitle('문의요청 실패');
      setModalDescription('문의 요청중 에러가 발생했습니다. 다시 시도해주세요.');
      setIsSuccess(false);
    }
  }, [requestStatus]);

  const onSubmit = async () => {
    await object()
      .shape({
        agreed: boolean().isTrue('개인정보 수집 및 이용에 대한 동의를 해주세요.'),
        description: string().required('설명을 입력 해주세요.').min(10, '설명은 10자 이상 입력 해주세요.'),
        title: string().required('제목을 입력 해주세요.').min(8, '제목은 8자 이상 입력 해주세요.'),
        email: string().required('이메일을 입력 해주세요.').email('올바른 이메일 형식이 아닙니다.'),
      })
      .validate({
        email,
        title,
        description,
        agreed,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(async ({ currentPassword, newPassword }) => {
        setRequestStatus(RequestStatus.FAIL);
      })
      .catch((err) => {
        if (err.errors) {
          setErrMsg(err.errors as string);
        }
      });
  };
  return (
    <ContentLayout>
      <Stack padding="56px" spacing={1}>
        <Typography variant="h1">문의 등록</Typography>
        <Typography variant="body1" align="left" paddingTop="24px">
          이메일 주소
          <b style={{ color: '#ff0000' }}>*</b>
        </Typography>
        <TextField
          fullWidth
          size="small"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="수신 가능한 이메일을 입력해주세요."
        />
        <Typography variant="body1" align="left" paddingTop="16px">
          제목
          <b style={{ color: '#ff0000' }}>*</b>
        </Typography>
        <TextField
          fullWidth
          size="small"
          type="email"
          value={title}
          onChange={onTitleChange}
          placeholder="제목을 입력해주세요."
        />
        <Typography variant="body1" align="left" paddingTop="16px">
          설명
          <b style={{ color: '#ff0000' }}>*</b>
        </Typography>
        <TextField
          multiline
          rows={5}
          fullWidth
          size="small"
          type="email"
          value={description}
          onChange={onDescriptionChange}
          placeholder="상세한 문의 사항을 기입해주세요."
        />
        <Stack>
          <Typography variant="body2" align="left" paddingTop="16px" fontWeight={600}>
            개인정보 수집 및 이용에 대한 동의 내용
          </Typography>
          <Typography variant="body2" align="left">
            ①개인정보 수집 항목: 이메일
            <br />
            ②수집목적: 고객식별, 문의 응대, 서비스 품질 향상
            <br />
            ③보유 및 이용기간: 수집 목적이 달성되면 지체없이 모든 개인정보를 파기합니다. 단, 관계법령에서 일정 기간
            정보의 보관을 규정한 경우에 한해 분리 보관 후 파기합니다.
          </Typography>
          <Typography variant="body2" align="left">
            *위 동의는 거부할 수 있으며, 거부시 해당 문의를 처리할 수 없습니다.
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          <Checkbox
            inputProps={{ 'aria-label': 'controlled' }}
            checked={agreed}
            onChange={onAgreeChange}
            sx={{ width: '24px' }}
          />
          <Typography variant="body2" fontWeight={600} paddingLeft="8px">
            동의합니다
            <b style={{ color: '#ff0000' }}>*</b>
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <Button variant="contained" sx={{ marginTop: '24px', width: '240px' }} onClick={onSubmit}>
            <Typography variant="button">제출</Typography>
          </Button>
        </Stack>
      </Stack>
      <Snackbar
        open={!!errMsg}
        message={errMsg}
        TransitionComponent={Fade}
        onClose={() => setErrMsg(undefined)}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <SuccessErrorModal
        isSuccess={isSuccess}
        open={requestStatus === RequestStatus.SUCCESS || requestStatus === RequestStatus.FAIL}
        onClose={() => setRequestStatus(RequestStatus.IDLE)}
        title={modalTitle}
        description={modalDescription}
      />
    </ContentLayout>
  );
}

import 'react-loading-skeleton/dist/skeleton.css';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import AppContext from '../../AppContext';
import { getPersonalizedRecommendations } from '../../api/user';
import MoreProfile from '../../common/more-profile';
import ProfileTags from '../../common/profile-tags';
import { ContentLayout, MainLayout } from '../../common/style';
import TopPick from '../../common/top-pick';
import { UserCoverLetterListItem, UserTag } from '../../models';
import { usePurchaseModals } from '../../common/modal/purchase-modal';

interface Props {
  rank: number;
  logo: string;
  company: string;
  comment: string;
  userTags: UserTag[];
  myTags: UserTag[];
  onClick: () => void;
}

function RecommendationCard(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rank, logo, company, userTags, myTags, comment, onClick } = props;

  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia sx={{ position: 'relative' }}>
          <Box
            position="absolute"
            top="12px"
            right="12px"
            border={`1px solid ${theme.palette.primary.main}`}
            p="4px 12px"
            borderRadius={1}
          >
            <Typography variant="h3" color={theme.palette.primary.main}>
              #
              {rank}
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" paddingTop={2} paddingLeft={2}>
            <Box
              width="120px"
              height="120px"
              sx={{
                background: `center / 130% no-repeat url("${logo}")`,
              }}
            />
            <Typography variant="h2" color={theme.palette.grey[600]}>
              {company}
            </Typography>
          </Stack>
        </CardMedia>
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="h3" color={theme.palette.grey[600]}>
              {comment}
            </Typography>
            <ProfileTags givenTags={userTags} myTags={myTags} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default function PickPage() {
  const { customer } = useContext(AppContext);
  const [personalRecommendations, setPersonalRecommendations] = useState<UserCoverLetterListItem[]>();

  const { handleCardClick, PurchaseModalsComponent } = usePurchaseModals(customer);
  const theme = useTheme();

  useEffect(() => {
    if (!customer) {
      return;
    }

    (async () => {
      const data = await getPersonalizedRecommendations(customer.publicId);
      setPersonalRecommendations(data);
    })();
  }, [customer]);

  const content = useMemo(() => {
    if (personalRecommendations) {
      if (personalRecommendations.length > 0) {
        const firstRank = personalRecommendations[0];
        const remains = personalRecommendations.slice(1, 5);
        return (
          <>
            <TopPick item={firstRank} myTags={customer?.userProfile?.profileTags ?? []} />
            <Grid container alignItems="space-around" columnGap={4} rowGap={4} wrap="wrap" justifyContent="left">
              {remains.map((recommendation, index) => (
                <Grid key={recommendation.id} item md={5}>
                  <RecommendationCard
                    rank={index + 2}
                    logo={recommendation.recruitment.company.ciUri}
                    company={recommendation.recruitment.company.korName}
                    comment={recommendation.profile.summary}
                    userTags={recommendation.profile.profileTags}
                    myTags={customer?.userProfile?.profileTags ?? []}
                    onClick={() => handleCardClick(content)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        );
      }

      return <MoreProfile />;
    }
    return <Skeleton height="40vh" />;
  }, [customer?.userProfile?.profileTags, handleCardClick, personalRecommendations]);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={6}>
          <Stack spacing={1}>
            <Typography variant="h1">
              {customer?.name}
              님과 유사한 지원자의 합격 자소서!
            </Typography>
            <Typography variant="h3" color={theme.palette.grey[600]}>
              입력하신 정보를 기반으로&nbsp;
              {customer?.name}
              님과 가장 유사한 합격자의 자기소개서를 제안드려요.
            </Typography>
          </Stack>
          {content}
        </Stack>
      </ContentLayout>
      <Box
        width="100%"
        paddingY={8}
        sx={{ background: 'linear-gradient(180deg, rgba(75, 190, 201, 0.13) 0%, rgba(240, 240, 240, 0.51) 100%)' }}
      >
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h1">커리어 맞춤 Pick!</Typography>
          <Typography variant="h2">더 많은 정보를 입력할수록 자세한 취업 콘텐츠를 확인할 수 있어요.</Typography>
          <Button variant="contained" size="large" href="/my-page">
            바로 확인하기
          </Button>
        </Stack>
      </Box>
      <PurchaseModalsComponent />
    </MainLayout>
  );
}

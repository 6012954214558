import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppContext from '../../../AppContext';
import { getFavoriteUserCoverLetters } from '../../../api/favorites';
import { ContentLayout, MainLayout } from '../../../common/style';
import { RecentUserCoverLetterListItem } from '../../../models';
import { toRelativeDateString } from '../../../util/date-util';
import CoverLetterTable, { getCommonColumnDefs } from '../cover-letter-table';

const PAGE_SIZE = 100;
const columnDefs = getCommonColumnDefs<RecentUserCoverLetterListItem>().concat({
  headerName: '한 줄 요약',
  field: 'profile.summary',
}, {
  headerName: '등록일시',
  field: 'createAt',
  valueGetter: ({ row }) => toRelativeDateString(new Date(row.createAt)),
});

export default function FavoriteCoverLetterPage() {
  const { customer } = useContext(AppContext);

  const {
    data: userCoverLetterPages,
    fetchNextPage: fetchNextUserCoverLetterPages,
    hasNextPage: hasNextUserCoverLetterPage,
  } = useInfiniteQuery({
    queryKey: ['favoriteCoverLetters', customer?.publicId] as [
      string,
      string | undefined,
    ],
    queryFn: async (context) => {
      const publicUserId = context.queryKey[1]!;
      const ret = await getFavoriteUserCoverLetters(publicUserId, {
        page: context.pageParam,
        size: PAGE_SIZE,
      });
      return ret;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? null : lastPage.number + 1),
    enabled: !!customer,
  });

  const userCoverLetters = useMemo(
    () => userCoverLetterPages?.pages
      .flatMap((e) => (e.empty
        ? [] : e.content!.map((favorite) => ({ ...favorite.content, createAt: favorite.createAt }) as RecentUserCoverLetterListItem)
      )) ?? [],
    [userCoverLetterPages],
  );

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" paddingBottom={7}>
            <Typography variant="subtitle2" flex={1}>
              즐겨찾기
            </Typography>
          </Stack>
          <InfiniteScroll
            dataLength={userCoverLetters?.length ?? 0}
            next={() => {
              fetchNextUserCoverLetterPages();
            }}
            hasMore={hasNextUserCoverLetterPage}
            loader={(
              <Box display="flex" paddingY={4} justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            scrollThreshold={0.9}
            hasChildren
          >
            <CoverLetterTable
              rows={userCoverLetters}
              columnDefs={columnDefs}
              getRowId={(item) => item.id}
              onRowClick={(item) => {
                window.open(`/cover-letter/${item.id}`, '_blank', 'noreferrer');
              }}
            />
          </InfiniteScroll>

        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

import { Box, Stack, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout, MainLayout } from '../../common/style';
import SettingHomeHeader from './header';
import InterestedCompany from './interested-company';
import SettingHomeProfile from './profile';

interface TabPanelProps<Key extends unknown> {
  children?: React.ReactNode;
  index: Key;
  value: Key;
}

function TabPanel<Key extends unknown>(props: TabPanelProps<Key>) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const a11yProps = <Key extends unknown>(index: Key) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

type TabValue = 'profile' | 'interested-companies' | 'payments';

export default function SettingHome() {
  const { tab } = useParams<{ tab: TabValue }>();
  const [selectedTab, setSelectedTab] = useState<TabValue>('profile');
  const navigate = useNavigate();

  const onTabChange = (event: SyntheticEvent, newValue: TabValue) => {
    navigate(`/my-page/${newValue}`);
  };

  useEffect(() => {
    if (!tab) {
      return;
    }

    setSelectedTab(tab);
  }, [tab]);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={6}>
          <SettingHomeHeader />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={onTabChange} aria-label="basic tabs example">
              <Tab label="프로필" value="profile" {...a11yProps('profile')} />
              <Tab label="관심회사" value="interested-companies" {...a11yProps('interested-companies')} />
              <Tab label="결제내역" value="payments" {...a11yProps('payments')} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index="profile">
            <SettingHomeProfile />
          </TabPanel>
          <TabPanel value={selectedTab} index="interested-companies">
            <InterestedCompany />
          </TabPanel>
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

import { Stack } from '@mui/material';

import SampleImg from '../../assets/sample.jpg';
import { ContentLayout, MainLayout } from '../../common/style';
import EditorPick from './editor-pick';
import PersonalRecommendation from './personal-recommendation';

function HomePage() {
  return (
    <MainLayout>
      <img src={SampleImg} width="100%" alt="home-banner" />
      <ContentLayout>
        <Stack spacing={6}>
          <PersonalRecommendation />
          <EditorPick />
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

export default HomePage;

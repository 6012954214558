import Button from '@mui/material/Button';
import { loadTossPayments } from '@tosspayments/tosspayments-sdk';
import { ChangeEvent, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm';
const customerKey = 'IGRPnNjEZYkeVIutZa53g';

interface Props {
  name: string;
  price: number;
  coupons?: (Coupon[] | null);
}

interface Coupon {
  name: string;
  discount: number;
}

export default function CheckoutPage(props: Props) {
  const {
    name,
    price,
    coupons,
  } = props;
  const [amount, setAmount] = useState({
    currency: 'KRW',
    value: price,
  });
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState<any>(null);

  // const { customer } = useContext(AppContext);

  useEffect(() => {
    async function fetchPaymentWidgets() {
      // ------  결제위젯 초기화 ------
      const tossPayments = await loadTossPayments(clientKey);
      // 회원 결제
      const paymentWidgets = tossPayments.widgets({
        customerKey,
      });
      // 비회원 결제
      // const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });

      setWidgets(paymentWidgets);
    }

    fetchPaymentWidgets();
  }, []);

  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      // ------ 주문의 결제 금액 설정 ------
      await widgets.setAmount(amount);

      await Promise.all([
        // ------  결제 UI 렌더링 ------
        widgets.renderPaymentMethods({
          selector: '#payment-method',
          variantKey: 'DEFAULT',
        }),
        // ------  이용약관 UI 렌더링 ------
        widgets.renderAgreement({
          selector: '#agreement',
          variantKey: 'AGREEMENT',
        }),
      ]);

      setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets, amount]);

  const handlePay = async () => {
    try {
      // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
      // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
      // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
      await widgets.requestPayment({
        orderId: 'JoYl9jSWDQ2rEcr-7U_Wk',
        orderName: `${name}`,
        successUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/fail`,
        // customerEmail: `${customer?.email}`,
        // customerName: `${customer?.name}`,
        // customerMobilePhone: `${customer?.phone}`,
      });
    } catch (error) {
      // 에러 처리하기

    }
  };

  const applyCoupon = async (event: ChangeEvent<HTMLInputElement>, coupon: Coupon) => {
    // ------  주문서의 결제 금액이 변경되었을 경우 결제 금액 업데이트 ------
    setAmount({
      ...amount,
      value: event.target.checked ? amount.value - coupon.discount : amount.value,
    });
  };

  useEffect(() => {
    if (widgets == null) {
      return;
    }

    widgets.setAmount(amount);
  }, [widgets, amount]);

  return (
    <div className="wrapper">
      <div className="box_section">
        {/* 결제 UI */}
        <Stack spacing={0}>
          <div id="payment-method" />
          {/* 이용약관 UI */}
          <div id="agreement" />
          {coupons?.map((item) => (
            <div>
              <label htmlFor="coupon-box">
                <input
                  id="coupon-box"
                  type="checkbox"
                  aria-checked="true"
                  disabled={!ready}
                  onChange={(event) => applyCoupon(event, item)}
                />
                <span>{item.name}</span>
              </label>
            </div>
          ))}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!ready}
            onClick={handlePay}
          >
            결제하기
          </Button>
        </Stack>
      </div>
    </div>
  );
}

import { Button, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';

import NoInfoImg from '../assets/noinfo.png';

export default function MoreProfile() {
  const theme = useTheme();

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Stack p={6} borderRadius={1} alignItems="center" spacing={3}>
          <Typography variant="h1" textAlign="center">
            커리어 네비게이터에서
            <br />
            나에게 딱 맞는 합격 정보만 쏙쏙!
          </Typography>
          <img src={NoInfoImg} width={200} alt="No information" />
          <Typography variant="h2">
            커리어 네비게이터 맞춤 콘텐츠를 제공받기 위해 프로필 정보를 입력해주세요.
          </Typography>
          <Button variant="contained" size="large" href="/my-page">
            프로필 등록하러 가기
          </Button>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            잠깐! 맞춤 컨텐츠는&nbsp;
            <b>관심 회사</b>
            와&nbsp;
            <b>학력</b>
            을 입력해야 볼 수 있어요.
            <br />
            프로필 정보를 더 많이 입력할수록 나에게 딱 맞는 합격 컨텐츠를 제공받을 수 있어요.
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

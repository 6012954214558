import { CheckCircle, Error } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface Props {
  isSuccess: boolean;
  title: string;
  description: string;
  open: boolean;
  onConfirm?: () => void;
  onClose: () => void;
}

export default function SuccessErrorModal(props: Props) {
  const {
    isSuccess, title, description, open, onClose, onConfirm,
  } = props;

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} disableAutoFocus>
      <DialogContent sx={{ width: '420px' }}>
        <Stack alignItems="center" spacing={3}>
          {isSuccess ? (
            <CheckCircle color="success" sx={{ fontSize: '60px' }} />
          ) : (
            <Error color="error" sx={{ fontSize: '60px' }} />
          )}
          <Stack alignItems="center" spacing={2}>
            <Typography id="transition-modal-title" variant="h2" component="h2">
              {title}
            </Typography>
            <Typography id="transition-modal-description" variant="body1" color={theme.palette.grey[500]} textAlign="center">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => { if (onConfirm) { onConfirm(); } onClose(); }}
        >
          확인
        </Button>
        {onConfirm && (
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => { onClose(); }}
          >
            취소
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

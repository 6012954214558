import { Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { getMyUserCoverLetter } from '../../api/user-cover-letter';
import AppContext from '../../AppContext';
import { ContentLayout, MainLayout } from '../../common/style';
import { ServerError, UserCoverLetter } from '../../models';
import MyCoverLetterEditor from './my-cover-letter-editor';
import { useCommonSnackbar } from '../../common/hooks';

export default function MyCoverLetterDetailPage() {
  const {
    customer,
  } = useContext(AppContext);

  const [userCoverLetter, setUserCoverLetter] = useState<UserCoverLetter>();
  const [snackbar, setFeedback] = useCommonSnackbar();

  const {
    myCoverLetterId,
  } = useParams();

  useEffect(() => {
    if (!customer || !myCoverLetterId) {
      return;
    }

    (async () => {
      try {
        const loaded = await getMyUserCoverLetter(customer.publicId, Number(myCoverLetterId));
        setUserCoverLetter(loaded);
      } catch (error) {
        if (error instanceof ServerError) {
          if (error.code / 100 === 4) {
            setFeedback({ message: error.message, level: 'error' });
          } else {
            setFeedback({ message: error.message, level: 'error' });
          }
        }
      }
    })();
  }, [customer, myCoverLetterId, setFeedback]);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" paddingBottom={7}>
            <Typography variant="subtitle2" flex={1}>
              작성하기
            </Typography>
          </Stack>
          {
            userCoverLetter
              ? (<MyCoverLetterEditor userCoverLetter={userCoverLetter} />)
              : (<Skeleton height="50vh" />)
          }
        </Stack>
      </ContentLayout>
      {snackbar}
    </MainLayout>
  );
}

import { Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useTheme } from '@mui/material';

interface Props {
  company: string;
  logo: string;
  comment: string;
  semester: string;
  year: string;
  employeeType: string;
  separation?: string[];
  onClick?: () => void;
}
export default function CoverLetterCard(props: Props) {
  const { company, logo, comment, semester, year, employeeType, separation, onClick } = props;

  const theme = useTheme();

  return (
    <Card
      sx={{
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia component="div">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              width="120px"
              height="120px"
              sx={{
                background: `center / 130% no-repeat url("${logo}")`,
              }}
            />
            <Stack spacing={1}>
              <Typography variant="h2" color={theme.palette.grey[700]}>
                {company}
              </Typography>
              <Typography variant="h3" color={theme.palette.grey[600]}>
                {year}
                &nbsp;
                {semester}
                &nbsp;
                {employeeType}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[400]}>
                {separation?.join(', ')}
              </Typography>
            </Stack>
          </Stack>
        </CardMedia>
        <CardContent>
          <Typography
            variant="body1"
            color={theme.palette.grey[600]}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
            }}
            lineHeight={1.5}
            height="60px"
            sx={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
          >
            {comment}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { CompositeDecorator, ContentBlock, ContentState, Editor, EditorState } from 'draft-js';
import { find } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import ContentLengthChecker from '../../common/cover-letter/content-length-checker';
import QuestionWrapper from '../../common/cover-letter/question-wrapper';
import { GuideDetailTooltip } from '../../common/tooltip';
import { GuideWord, LimitType } from '../../models';

interface Props {
  isCheck: boolean;
  guideWords: GuideWord[];
  question: string;
  limit?: number;
  limitType?: LimitType;
  answer: string;
  setAnswer: (answer: string) => void;
  tags?: string[];
  isParent: boolean;
}

const styles = {
  editor: {
    border: '1px solid gray',
    borderRadius: '8px',
    minHeight: '16em',
    width: '100%',
    padding: '8px',
    cursor: 'text',
  },
};

export default function QuestionEditor(props: Props) {
  const { isCheck, guideWords, tags, question, answer, limit, limitType, setAnswer, isParent } = props;

  const editor = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(answer)));

  const handleStrategy = useCallback(
    (contentBlock: ContentBlock, callback: (start: number, end: number) => void) => {
      const text = contentBlock.getText();
      guideWords
        .map((item) => item.word)
        .forEach((word) => {
          const matches = [...text.matchAll(new RegExp(word, 'gim'))];
          matches.forEach((match) => {
            if (match.index !== undefined) {
              callback(match.index, match.index + match[0].length);
            }
          });
        });
    },
    [guideWords],
  );

  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-shadow
  function Decorated(props: { children: any }) {
    const { children } = props;

    const theme = useTheme();

    // 완전 일치하는 경우만 표시한다
    const guideWord = find(guideWords, (item) => item.word === children[0].props.text)!;
    if (!guideWord) {
      return children;
    }

    const backgroundColor = theme.palette.primary.light;
    const color = theme.palette.grey[900];

    const { description: definition, guide } = guideWord;

    return (
      <GuideDetailTooltip
        key={children[0].props.id}
        title={(
          <Stack spacing={1} whiteSpace="pre-wrap">
            <Typography variant="body2">{definition}</Typography>
            <Typography variant="caption">{guide}</Typography>
          </Stack>
        )}
        arrow
        placement="top"
      >
        <span style={{ backgroundColor, color }}>{children}</span>
      </GuideDetailTooltip>
    );
  }

  useEffect(() => {
    if (isCheck) {
      setEditorState(
        EditorState.set(editorState, {
          decorator: new CompositeDecorator([
            {
              strategy: handleStrategy,
              component: Decorated,
            },
          ]),
        }),
      );
    } else {
      setEditorState(EditorState.set(editorState, { decorator: new CompositeDecorator([]) }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStrategy, isCheck]);

  function focusEditor() {
    if (editor.current) {
      (editor.current as unknown as any).focus();
    }
  }

  const text = editorState.getCurrentContent().getPlainText('\u0001');

  return (
    <QuestionWrapper question={question} tags={tags} isParent={isParent}>
      {limit && limitType && <ContentLengthChecker content={text} limit={limit} limitType={limitType} />}
      <Box onClick={() => focusEditor()} style={styles.editor} aria-hidden="true">
        <Editor
          ref={editor}
          editorState={editorState}
          onBlur={() => {
            setAnswer(text);
          }}
          onChange={(newState) => setEditorState(newState)}
        />
      </Box>
    </QuestionWrapper>
  );
}

import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const removeCookie = (key: string) => cookies.remove(key);

export const getCookie = (key: string) => cookies.get(key);

export const setCookie = (key: string, val: any, options?: CookieSetOptions) => {
  //  maxAge: number = 12 * 60 * 1000) => {
  cookies.set(key, val, options);
};

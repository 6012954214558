import { Company } from './industry.model';

export default interface Recruitment {
  id: number;
  year: string;
  semester: SemesterType;
  employeeType: string;
  separation?: string[];
  company: Company;
}

export enum SemesterType {
  FirstHalf = 'FIRST_HALF',
  SecondHalf = 'SECOND_HALF',
  Occasional = 'OCCASIONAL',
}

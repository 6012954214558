import { Button, Stack, Typography, useTheme } from '@mui/material';

import IntroBG from '../../assets/intro_bg.png';
import { ContentLayout } from '../../common/style';

export default function HeroSectionView() {
  const theme = useTheme();
  return (
    <Stack
      paddingTop={{
        xs: '100px', sm: '100px', md: '140px', lg: '180px',
      }}
      paddingBottom={{
        xs: '100px', sm: '100px', md: '140px', lg: '180px',
      }}
      alignItems="center"
      sx={{
        width: '100%',
        background: `center / auto 100% no-repeat url(${IntroBG})`,
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
        overflow: 'clip',
      }}
    >
      <ContentLayout sx={{
        marginLeft: {
          xs: '56px', sm: '160px', md: '240px',
        },
        alignItems: 'center',
      }}
      >
        <Stack spacing={3}>
          <Typography variant="subtitle1" sx={{ color: 'white' }} gutterBottom>
            <span style={{ color: theme.palette.primary.main }}>CAREER</span>
            &nbsp;
            <span>Navigator</span>
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'white' }}>
            내가 가고 싶은 기업에&nbsp;
            <b>합격한 지원자</b>
            의
            <br />
            <b>자기소개서</b>
            를 지금 만나보세요!
          </Typography>
          <Typography variant="body1" sx={{ color: 'white', paddingBottom: '56px' }}>
            1,582개 회사에 합격한 85,203개의 자기소개서가 기다리고 있습니다.
          </Typography>
          <Button
            variant="contained"
            size="large"
            fullWidth={false}
            sx={{ width: '180px', paddingY: '12px' }}
            color="secondary"
            href="/login"
          >
            시작하기
          </Button>
        </Stack>
      </ContentLayout>
    </Stack>
  );
}

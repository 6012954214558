import { styled, Switch } from '@mui/material';

const getCheckImage = (color: string) => `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${
  encodeURIComponent(color)
}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`;

const StyledSwitch = styled(Switch)(({
  theme,
}) => ({
  padding: 4,

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': { backgroundImage: getCheckImage(theme.palette.primary.main) },
      '&.Mui-disabled + .MuiSwitch-track': { opacity: 0.5 },
    },
  },

  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    boxSizing: 'border-box',
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 24,
    height: 24,
    transform: 'translateX(-2px) translateY(-2px)',
    backgroundColor: theme.palette.common.white,
    backgroundPosition: 'center',
    backgroundImage: getCheckImage(theme.palette.grey[300]),
  },
}));

export default StyledSwitch;

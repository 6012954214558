import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import AppContext from '../../AppContext';
import { addFavoriteCompanies, getFavoriteCompanies, getStatusOfUser, getUser, updateProfiles } from '../../api/user';
import NoInfoImg from '../../assets/noinfo.png';
import { useCommonSnackbar } from '../../common/hooks';
import { Company, FavoriteCompany, UserTag } from '../../models';
import { EditEducationContent } from './dialogs/edit-education-dialog';
import { InterestedCompanyContent } from './interested-company';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function RequiredProfile(props: Props) {
  const { open, onClose } = props;

  const { customer, setCustomer, setUserStatus } = useContext(AppContext);

  const [userTags, setUserTags] = useState<UserTag[]>([]);
  const [favoriteCompanies, setFavoriteCompanies] = useState<FavoriteCompany[]>([]);
  const [snackbar, setFeedback] = useCommonSnackbar();

  const [grade, setGrade] = useState<UserTag>();
  const [institution, setInstitution] = useState<UserTag>();
  const [major, setMajor] = useState<UserTag>();

  useEffect(() => {
    if (!customer) {
      return;
    }

    if (open) {
      setUserTags(customer.userProfile?.profileTags ?? []);

      (async () => {
        const data = await getFavoriteCompanies(customer.publicId);
        if (data.empty) {
          return;
        }

        setFavoriteCompanies(data.content);
      })();
    }
  }, [open, customer]);

  const addFavoriteCompany = (company: Company) => {
    setFavoriteCompanies((prev) => prev.concat({
      id: -company.id,
      company,
    }));
  };

  const deleteFavoriteCompany = (favoriteCompany: FavoriteCompany) => {
    setFavoriteCompanies((prev) => prev.filter((entry) => entry.company.id !== favoriteCompany.company.id));
  };

  const save = async () => {
    if (!customer) {
      return;
    }

    await updateProfiles(customer.publicId, userTags);
    await addFavoriteCompanies(
      customer.publicId,
      favoriteCompanies
        .filter((favoriteCompany) => favoriteCompany.id < 0)
        .map((favoriteCompany) => favoriteCompany.company),
    );

    // refresh user and status
    setCustomer(await getUser(customer.publicId));
    setUserStatus(await getStatusOfUser(customer.publicId));

    onClose();
  };

  const isPossibleToSave = useMemo(
    () => favoriteCompanies.length > 0 && grade && (grade.value !== '대학교' || (grade.value === '대학교' && institution)),
    [favoriteCompanies.length, grade, institution],
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <DialogContent>
        <Stack spacing={9} margin="0 auto">
          <Stack spacing={1}>
            <img src={NoInfoImg} width={200} alt="No information" />

            <Typography variant="h1">
              {customer?.name}
              님, 안녕하세요.
            </Typography>
            <Typography variant="h3" color={(theme) => theme.palette.grey[500]}>
              커리어 네비게이터 맞춤 콘텐츠를 제공받기 위해 프로필 정보를 입력해주세요.
            </Typography>
          </Stack>
          <InterestedCompanyContent
            favoriteCompanies={favoriteCompanies}
            addFavoriteCompany={addFavoriteCompany}
            deleteFavoriteCompany={deleteFavoriteCompany}
            setError={(message) => setFeedback({ level: 'error', message })}
            displayNothing={false}
          />
          <Stack spacing={2}>
            <Typography variant="h2" flex={1} textAlign="left">
              최종 졸업 또는 현재 재학 중인 학교와 전공을 입력해주세요.
            </Typography>
            <EditEducationContent
              grade={grade}
              setGrade={setGrade}
              institution={institution}
              setInstitution={setInstitution}
              major={major}
              setMajor={setMajor}
            />
          </Stack>
        </Stack>
        {snackbar}
      </DialogContent>
      <DialogActions>
        <Button variant="text" size="small" onClick={onClose}>
          건너뛰기
        </Button>
        <Box flexGrow={1} />
        <Button variant="contained" onClick={save} disabled={!isPossibleToSave}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

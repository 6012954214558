import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getAnnouncements } from '../../api/notice';
import { ContentLayout, MainLayout } from '../../common/style';
import { Announcement } from '../../models';
import { toRelativeDateString } from '../../util/date-util';

export default function AnnouncementPage() {
  const [announcements, setAnnouncements] = useState<Announcement[]>();
  useEffect(() => {
    getAnnouncements()
      .then((newAnnouncements) => {
        setAnnouncements(newAnnouncements);
      });
  }, []);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack padding="56px">
          <Stack direction="row" alignItems="center" marginBottom="56px">
            <Typography variant="h1">공지사항</Typography>
          </Stack>
          <TableContainer sx={{ borderTop: '2px solid black', borderBottom: '1px solid black' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="60px">
                    <Typography variant="body1">
                      No.
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">
                      제목
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="140px">
                    <Typography variant="body1">
                      등록일
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="140px">
                    <Typography variant="body1">
                      조회수
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {announcements?.map((row, idx) => (
                  <TableRow key={`a-item-${row.id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center">{announcements.length - idx}</TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="center">{toRelativeDateString(row.createdAt)}</TableCell>
                    <TableCell align="center">
                      <Typography alignSelf="center">{row.viewCount}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

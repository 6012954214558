import { AddCircle, Close, RemoveCircle } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { getStatusOfUser, getUser, updateJobExperiences } from '../../../api/user';
import CompanySelectBox from '../../../common/input/company-select-box';
import { Company, JobExperience, UserTag } from '../../../models';
import { getEmploymentTypes } from '../../../util/meta';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function EditWorkDialog(props: Props) {
  const { open, onClose } = props;

  const { customer, setCustomer, setUserStatus } = useContext(AppContext);

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState<UserTag>();

  const [experiences, setExperiences] = useState<JobExperience[]>([]);

  const navigate = useNavigate();

  const { data: employmentTypes } = useQuery({
    queryKey: ['employmentTypes'],
    queryFn: getEmploymentTypes,
    initialData: [],
  });

  const changeEmploymentType = (e: SelectChangeEvent<UserTag>) => {
    setSelectedEmploymentType(employmentTypes.find((entry) => entry.id === Number(e.target.value)));
  };

  const canAdd = useMemo(
    () => !!selectedCompany
      && !!selectedEmploymentType
      && !experiences.some(
        (experience) => experience.company.id === selectedCompany.id && experience.employmentType.id === selectedEmploymentType.id,
      ),
    [selectedCompany, selectedEmploymentType, experiences],
  );

  const addWorkExperience = () => {
    if (!canAdd) {
      return;
    }

    setExperiences((prev) => prev.concat({ company: selectedCompany!, employmentType: selectedEmploymentType! }));
    setSelectedCompany(null);
    setSelectedEmploymentType(undefined);
  };

  const removeWorkExperience = (index: number) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    setExperiences(newExperiences);
  };

  const saveProfile = async () => {
    if (!customer) {
      return;
    }

    await updateJobExperiences(customer.publicId, experiences);

    // refresh user and status
    setCustomer(await getUser(customer.publicId));
    setUserStatus(await getStatusOfUser(customer.publicId));

    onClose();
  };

  useEffect(() => {
    setExperiences(customer?.userProfile?.jobExperiences ?? []);
  }, [customer]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h2" flex={1} textAlign="left" component="span">
            직무 경험이 있으신가요?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <Typography variant="caption" color={(theme) => theme.palette.grey[500]}>근무했던 회사를 찾을 수 없나요?</Typography>
            <Button
              variant="text"
              size="small"
              onClick={() => { navigate({ pathname: '.' }, { state: { requestCompany: true } }); onClose(); }}
            >
              등록 요청하기
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2} flex={1}>
              <FormControl sx={{ flex: 1 }}>
                <CompanySelectBox
                  fullWidth
                  company={selectedCompany}
                  setCompany={setSelectedCompany}
                />
              </FormControl>
              <FormControl sx={{ minWidth: 140 }}>
                <Select
                  displayEmpty
                  value={selectedEmploymentType ?? ''}
                  renderValue={(value) => value.value}
                  onChange={changeEmploymentType}
                >
                  <MenuItem disabled value="">
                    고용형태 선택
                  </MenuItem>
                  {employmentTypes.map((item) => (
                    <MenuItem value={item.id} key={`employment-type-option-${item.id}`}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Button variant="text" startIcon={<AddCircle />} onClick={addWorkExperience} disabled={!canAdd}>
              추가
            </Button>
          </Stack>
          <Stack spacing={1} marginY={3} height="160px">
            {experiences.map(({ company, employmentType }, index) => (
              <Stack
                key={`${company.id}-${employmentType.id}`}
                direction="row"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack key={`${company.id}-${employmentType}`} direction="row" alignItems="center" spacing={2}>
                  <Chip
                    label={company.korName}
                    variant="filled"
                    color="primary"
                    sx={(theme) => ({
                      color: theme.palette.grey[900],
                    })}
                  />
                  <Chip
                    label={employmentType.value}
                    variant="filled"
                    color="primary"
                    sx={(theme) => ({
                      color: theme.palette.grey[900],
                    })}
                  />
                </Stack>
                <Button
                  variant="text"
                  startIcon={<RemoveCircle />}
                  color="secondary"
                  onClick={() => removeWorkExperience(index)}
                >
                  삭제
                </Button>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={saveProfile}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../AppContext';
import { MainLayout } from '../../common/style';
import RegistrationCompleteView from './registration-complete';
import RegistrationFormView from './registration-form';

export default function RegistrationView() {
  const {
    customer,
  } = useContext(AppContext);
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    if (customer) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [customer]);

  const theme = useTheme();

  return (
    <MainLayout style={{ background: theme.palette.grey[50] }}>
      {isComplete ? <RegistrationCompleteView /> : <RegistrationFormView onFinished={() => setIsComplete(true)} />}
    </MainLayout>
  );
}

import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContentLayout, MainLayout } from '../../common/style';
import { Pass } from '../../models/pass.model';
import { getPass } from '../../api/pass';
import PassCard from './pass-card';
import CheckoutPage from '../checkout';
import CommonModal from '../../common/modal/common-modal';

export default function PassPage() {
  const [passItems, setPassItems] = useState<Pass[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Pass | null>(null);
  // FIXME: coupon 가져오는 api를 만들고 coupon을 꽂아넣도록 하기. 그리고 이를 CheckoutPage에 넣기.
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCardClick = async (item: Pass) => {
    setSelectedItem(item);
    openModal();
  };

  useEffect(() => {
    (async () => {
      const data = await getPass();
      setPassItems(data);
    })();
  }, []);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" paddingBottom={7}>
            <Typography variant="subtitle2" flex={1}>
              이용권 구매
            </Typography>
          </Stack>
          <Grid container spacing={4} columns={passItems?.length}>
            {passItems?.map((item) => (
              <Grid item key={`g-item-${item.id}`}>
                <PassCard
                  name={item.name}
                  price={item.price}
                  description={item.description}
                  onClick={() => handleCardClick(item)}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </ContentLayout>
      <CommonModal
        open={isModalOpen}
        onClose={closeModal}
        title="결제"
        content={
          selectedItem
            ? (
              <CheckoutPage
                name={selectedItem?.name}
                price={selectedItem?.price}
              />
            )
            : <div>Loading...</div>
        }
      />
    </MainLayout>
  );
}

import { Close, RemoveCircle, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { compact, differenceBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import AppContext from '../../../AppContext';
import { postUserTag } from '../../../api/meta';
import { getStatusOfUser, getUser, updateProfiles } from '../../../api/user';
import { UserTag } from '../../../models';
import { getGrades, getInstitutions, getMajors } from '../../../util/meta';

interface EditEducationContentProps {
  grade?: UserTag;
  setGrade: (value: UserTag | undefined) => void;
  institution?: UserTag;
  setInstitution: (value: UserTag | undefined) => void;
  major?: UserTag;
  setMajor: (value: UserTag | undefined) => void;
}

const filter = createFilterOptions<UserTag>();

export function EditEducationContent(props: EditEducationContentProps) {
  const { grade, setGrade, institution, setInstitution, major, setMajor } = props;

  const { data: gradeList } = useQuery({ queryKey: ['grades'], queryFn: getGrades, initialData: [] });

  const { data: institutionList } = useQuery({
    queryKey: ['institutions', grade],
    queryFn: (context) => {
      const targetGrade = context.queryKey[1] as UserTag | undefined;
      if (!targetGrade || targetGrade.value === '고등학교') {
        return null;
      }
      return getInstitutions();
    },
    enabled: !!grade,
  });

  const { data: majorList } = useQuery({
    queryKey: ['majors', institution],
    queryFn: getMajors,
    enabled: !!institution,
  });

  useEffect(() => {
    setInstitution(undefined);
    setMajor(undefined);
  }, [grade, setInstitution, setMajor]);

  useEffect(() => {
    if (!institution) {
      setMajor(undefined);
    }
  }, [institution, setMajor]);

  const changeGrade = (e: SelectChangeEvent<UserTag>) => {
    if (e.target.value === '') {
      setGrade(undefined);
    } else {
      setGrade(gradeList.find((entry) => entry.id === Number(e.target.value)));
    }
  };

  const changeMajor = (e: SelectChangeEvent<UserTag>) => {
    if (e.target.value === '') {
      setMajor(undefined);
    } else {
      setMajor(majorList?.find((entry) => entry.id === Number(e.target.value)));
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <FormControl sx={{ minWidth: 150 }}>
        <Select value={grade ?? ''} renderValue={(value) => value.value} onChange={changeGrade}>
          {gradeList.map((item) => (
            <MenuItem value={item.id} key={`etype-option-${item.id}`}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        key="institution"
        value={institution ?? ''}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setInstitution({
              id: -1,
              category: '학교',
              value: newValue,
            });
          } else if (newValue) {
            setInstitution(newValue);
          } else {
            setInstitution(undefined);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              id: -1,
              category: '학교',
              value: params.inputValue,
            });
          }

          return filtered;
        }}
        disabled={!institutionList}
        options={institutionList ?? []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }

          if (option.value) {
            return option.value;
          }

          return option.value;
        }}
        sx={{ minWidth: 300, flex: 1 }}
        freeSolo
        clearOnBlur
        blurOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="학교/기관명을 입력해주세요."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <FormControl sx={{ minWidth: 120 }}>
        <Select
          disabled={!institution}
          renderValue={(value) => value.value}
          value={major ?? ''}
          displayEmpty
          onChange={changeMajor}
        >
          <MenuItem value="">선택 안 함</MenuItem>
          {majorList?.map((item) => (
            <MenuItem value={item.id} key={`major-option-${item.id}`}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function EditEducationDialog(props: Props) {
  const { open, onClose } = props;

  const { customer, setCustomer, setUserStatus } = useContext(AppContext);

  const [userTags, setUserTags] = useState<UserTag[]>([]);

  const [isAlreadySet, setIsAlreadySet] = useState(true);
  const [grade, setGrade] = useState<UserTag>();
  const [institution, setInstitution] = useState<UserTag>();
  const [major, setMajor] = useState<UserTag>();

  useEffect(() => {
    if (open) {
      const profileTags = customer?.userProfile?.profileTags ?? [];

      const existingGrade = profileTags.find((tag) => tag.category === '학력');
      const existingInstitution = profileTags.find((tag) => tag.category === '학교');
      const existingMajor = profileTags.find((tag) => tag.category === '전공');

      setGrade(existingGrade);
      setInstitution(existingInstitution);
      setMajor(existingMajor);
      setIsAlreadySet(!!existingGrade);

      setUserTags(differenceBy(profileTags, [existingGrade, existingInstitution, existingMajor], 'id'));
    }
  }, [open, customer]);

  const saveProfile = async () => {
    if (!customer) {
      return;
    }

    const newTags = [grade];

    if (institution) {
      if (institution.id === -1) {
        const newTag = await postUserTag({
          category: institution.category,
          value: institution.value,
        });
        newTags.push(newTag);
      } else {
        newTags.push(institution);
      }
    }

    newTags.push(major);

    await updateProfiles(customer.publicId, userTags.concat(...compact(newTags)));

    // refresh user and status
    setCustomer(await getUser(customer.publicId));
    setUserStatus(await getStatusOfUser(customer.publicId));

    onClose();
  };

  const deleteEducation = () => {
    setGrade(undefined);
    setInstitution(undefined);
    setMajor(undefined);
    setIsAlreadySet(false);
  };

  const isPossibleToAdd = useMemo(
    () => grade && (grade.value !== '대학교' || (grade.value === '대학교' && institution)),
    [grade, institution],
  );

  const educations = useMemo(() => compact([grade, institution, major]), [grade, institution, major]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle component="div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h2" flex={1} textAlign="left">
            최종 졸업 또는 현재 재학 중인 학교와 전공을 입력해주세요.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 4 }}>
        {isAlreadySet ? (
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center" flexGrow={1}>
              {educations.map((item) => (
                <Chip
                  key={`education-item-${item.id}`}
                  label={item.value}
                  variant="filled"
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.grey[900],
                  })}
                />
              ))}
            </Stack>
            <Button onClick={() => deleteEducation()} startIcon={<RemoveCircle />} color="secondary">
              삭제
            </Button>
          </Stack>
        ) : (
          <EditEducationContent
            grade={grade}
            setGrade={setGrade}
            institution={institution}
            setInstitution={setInstitution}
            major={major}
            setMajor={setMajor}
          />
        )}
      </DialogContent>
      <DialogActions>
        {isAlreadySet ? (
          <Button variant="outlined" onClick={onClose}>
            닫기
          </Button>
        ) : (
          <Button variant="contained" onClick={saveProfile} disabled={!isPossibleToAdd}>
            저장
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

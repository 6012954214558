import { BusinessCenter, Edit, School, Verified, WorkspacePremium } from '@mui/icons-material';
import { Box, Card, Chip, Divider, LinearProgress, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import AppContext from '../../AppContext';
import { getStatusOfUser } from '../../api/user';
import { UserStatus, UserTag, activityCategories } from '../../models';
import EditActivityDialog from './dialogs/edit-activity-dialog';
import EditCertificateDialog from './dialogs/edit-certificate-dialog';
import EditEducationDialog from './dialogs/edit-education-dialog';
import EditWorkDialog from './dialogs/edit-work-dialog';
import RequestCompanyDialog from './dialogs/request-company-dialog';

const progressCaptionStyle: SxProps = {
  typography: 'caption',
  flex: 1,
  fontWeight: 600,
  textAlign: 'right',
};

function ProgressComponent(props: { userStatus?: UserStatus }) {
  const { userStatus } = props;

  return (
    <Stack spacing={5}>
      <Box borderRadius={2} px="32px" py="24px" sx={{ background: 'rgba(75,190,201,0.2)' }}>
        <Typography variant="h2" paddingBottom="24px">
          프로필 완성도
        </Typography>
        <LinearProgress
          variant="determinate"
          value={userStatus?.progress ?? 0}
          sx={{ height: '12px', borderRadius: '999px' }}
        />
        <Stack direction="row" marginTop="8px">
          <Typography sx={progressCaptionStyle}>20%</Typography>
          <Typography sx={progressCaptionStyle}>40%</Typography>
          <Typography sx={progressCaptionStyle}>60%</Typography>
          <Typography sx={progressCaptionStyle}>80%</Typography>
          <Typography sx={progressCaptionStyle}>100%</Typography>
        </Stack>
      </Box>
      <Typography variant="h2" align="center">
        내 정보를 더 많이 입력할수록 더 많은 취업 콘텐츠를 확인할 수 있어요.
      </Typography>
    </Stack>
  );
}

export default function SettingHomeProfile() {
  const { customer, userStatus, setUserStatus } = useContext(AppContext);

  const [openEducation, setOpenEducation] = useState(false);
  const [openWork, setOpenWork] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openRequestCompany, setOpenRequestCompany] = useState(false);

  const location = useLocation();

  const closeEducation = () => {
    setOpenEducation(false);
  };

  const closeWork = () => {
    setOpenWork(false);
  };

  const closeCertificate = () => {
    setOpenCertificate(false);
  };

  const closeActivity = () => {
    setOpenActivity(false);
  };

  const { profileTags, jobExperiences } = useMemo(() => ({
    profileTags: customer?.userProfile?.profileTags ?? [],
    jobExperiences: customer?.userProfile?.jobExperiences ?? [],
  }), [customer]);

  const theme = useTheme();

  const color = theme.palette.grey[600];

  const makeTag = (tag: UserTag) => (
    <Chip key={tag.id} variant="filled" color="primary" sx={{ color: theme.palette.grey[900] }} label={tag.value} />
  );

  useEffect(() => {
    if (!customer) {
      return;
    }

    (async () => {
      const status = await getStatusOfUser(customer.publicId);
      setUserStatus(status);
    })();
  }, [customer, setUserStatus]);

  const { education, language, certificate } = useMemo(() => {
    const educationTags = profileTags
      .filter((tag) => tag.category === '학력')
      .concat(profileTags.filter((tag) => tag.category === '학교'))
      .concat(profileTags.filter((tag) => tag.category === '전공'));

    const languageTags = profileTags.filter((tag) => tag.category === '어학');
    const certificateTags = profileTags.filter((tag) => tag.category.includes('자격증'));

    return {
      education: educationTags,
      language: languageTags,
      certificate: certificateTags,
    };
  }, [profileTags]);

  useEffect(() => {
    const value = location.state?.requestCompany as boolean | undefined;
    if (!value) {
      return;
    }

    setOpenRequestCompany(true);
  }, [location]);

  const nothing = <Typography variant="body1">없음</Typography>;

  return (
    <Stack>
      <ProgressComponent userStatus={userStatus} />
      <Stack spacing={2} marginTop={4}>
        <Card sx={{ p: 4, border: `1px solid ${theme.palette.grey[200]}` }}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <School sx={{ color }} />
              <Typography variant="h2" flex={1}>
                학력
              </Typography>
              <Edit sx={{ cursor: 'pointer', color }} onClick={() => setOpenEducation(true)} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} marginTop="24px">
              {education.length > 0 ? education.map(makeTag) : nothing}
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ p: 4, border: `1px solid ${theme.palette.grey[200]}` }}>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2} marginBottom={1}>
              <BusinessCenter sx={{ color }} />
              <Typography variant="h2" flex={1}>
                직무 경험
              </Typography>
              <Edit sx={{ cursor: 'pointer' }} onClick={() => setOpenWork(true)} />
            </Stack>
            {jobExperiences?.map(({ company, employmentType }) => (
              <Stack key={`${company.id}-${employmentType.id}`} direction="row" justifyContent="space-between" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Chip
                    label={company.korName}
                    variant="filled"
                    color="primary"
                    sx={{
                      color: theme.palette.grey[900],
                    }}
                  />
                  <Chip
                    label={employmentType.value}
                    variant="filled"
                    color="primary"
                    sx={{
                      color: theme.palette.grey[900],
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Card>
        <Card sx={{ p: 4, border: `1px solid ${theme.palette.grey[200]}` }}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Verified sx={{ color }} />
              <Typography variant="h2" flex={1}>
                어학 및 자격증
              </Typography>
              <Edit sx={{ cursor: 'pointer' }} onClick={() => setOpenCertificate(true)} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} marginTop="24px">
              <Typography variant="body1" fontWeight={600} width="120px">
                어학
              </Typography>
              {language.length > 0 ? language.map(makeTag) : nothing}
            </Stack>
            <Divider sx={{ marginTop: '16px' }} />
            <Stack direction="row" alignItems="center" spacing={2} marginTop="16px">
              <Typography variant="body1" fontWeight={600} width="120px">
                자격증
              </Typography>
              <div style={{ float: 'left', overflowWrap: 'break-word' }}>
                {certificate.length > 0 ? certificate.map(makeTag) : nothing}
              </div>
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ p: 4, border: `1px solid ${theme.palette.grey[200]}` }}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <WorkspacePremium sx={{ color }} />
              <Typography variant="h2" flex={1}>
                활동
              </Typography>
              <Edit sx={{ cursor: 'pointer' }} onClick={() => setOpenActivity(true)} />
            </Stack>
            {activityCategories.map((item) => {
              const tags = profileTags.filter((tag) => tag.category === item);

              return (
                <Stack direction="row" alignItems="center" spacing={2} marginTop="24px" key={`activity-item-${item}`}>
                  <Typography variant="body1" fontWeight={600} width="120px">
                    {item}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    {tags.length > 0 ? tags.map(makeTag) : nothing}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Card>
      </Stack>
      <EditEducationDialog open={openEducation} onClose={closeEducation} />
      <EditWorkDialog open={openWork} onClose={closeWork} />
      <EditCertificateDialog open={openCertificate} onClose={closeCertificate} />
      <EditActivityDialog open={openActivity} onClose={closeActivity} />
      <RequestCompanyDialog open={openRequestCompany} onClose={() => setOpenRequestCompany(false)} />
    </Stack>
  );
}

import { Button, Paper, Stack, Typography } from '@mui/material';

export default function RegistrationCompleteView() {
  return (
    <Paper
      sx={{
        marginTop: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        p: 8,
      }}
    >
      <Stack alignItems="center">
        <Typography variant="h1">이메일 인증</Typography>
        <Typography variant="h2" marginTop={4}>
          입력하신 이메일로 인증 이메일이 발송되었습니다.
        </Typography>
        <Typography variant="h2">가입 절차를 완료 해주세요.</Typography>
        <Button variant="contained" color="primary" href="/my-page" sx={{ margin: '32px' }}>
          마이페이지로 이동
        </Button>
      </Stack>
    </Paper>
  );
}

import { Button, Card, CardContent, CardHeader, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { string } from 'yup';

import { resetPassword } from '../../api/user';
import AppContext from '../../AppContext';
import { MainLayout } from '../../common/style';
import { ServerError } from '../../models';

export default function PasswordResetView() {
  const {
    customer,
  } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  useEffect(() => {
    if (customer) {
      navigate('/my-page');
    }
  }, [customer, navigate]);

  const onResetClick = async () => {
    try {
      const input = await string().email().required().validate(email);
      await resetPassword(input);

      setEmail('');
      alert('입력하신 이메일로 임시 비밀번호를 발송하였습니다.');
    } catch (error) {
      if (error instanceof ServerError) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(true);
      }
    }
  };

  return (
    <MainLayout style={{ background: theme.palette.grey[50] }}>
      <Card sx={{ marginTop: '80px', padding: '14px 20px' }}>
        <CardHeader title={<Typography variant="h1">비밀번호 재설정</Typography>} />
        <CardContent>
          <Stack spacing={1} width={{ sm: 480, xs: '100%' }}>
            <Typography variant="body2" align="left" paddingTop={1}>
              이메일
            </Typography>
            <TextField fullWidth size="small" type="email" value={email} onChange={handleEmailChange} />
            <Stack width="100%" alignItems="center" paddingTop={2}>
              <Button
                variant="contained"
                fullWidth
                onClick={onResetClick}
                color="primary"
              >
                비밀번호 재발급
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </MainLayout>
  );
}

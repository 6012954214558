import { Company } from './industry.model';

export interface Customer {
  publicId: string;
  email: string;
  name: string;
  gender: string;
  birthday: string;
  phone: string;
  userProfile?: UserProfile;
}

export interface JobExperience {
  company: Company;
  employmentType: UserTag;
}

export interface UserProfile {
  profileTags: UserTag[];
  jobExperiences?: JobExperience[];
  summary: string;
}

export interface UserTag {
  id: number;
  category: string;
  value: string;
  parent?: UserTag;
}

export interface University {
  id: number;
  name: string;
}

export interface UserStatus {
  isFirstLogin: boolean;
  shouldFillMandatory: boolean;
  progress: number;
}

export const activityCategories = ['대외활동(금융)', '대외활동(비금융)', '교내경험', '공모전', '봉사활동', '해외경험'];

export interface FavoriteCompany {
  id: number;
  company: Company;
}

export interface LoginResponse {
  accessToken: string;
  publicId: string
}

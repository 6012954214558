import { Stack, Typography, useTheme } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  question: string;
  tags?: string[];
  isParent: boolean;
}

export default function QuestionWrapper(props: Props) {
  const { children, question, tags, isParent } = props;

  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Typography variant={isParent ? 'h2' : 'h3'} color={theme.palette.grey[700]} lineHeight="1.5">
        {question}
      </Typography>
      {tags && (
        <Stack direction="row" spacing={1} paddingBottom={1} paddingLeft={1}>
          {tags.map((tag) => (
            <Typography key={tag} variant="h3" color={theme.palette.grey[500]}>
              #
              {tag}
            </Typography>
          ))}
        </Stack>
      )}
      {children}
    </Stack>
  );
}

import { AppBar, Box, Button, Container, Link, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function LandingAppBar() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" color="default" sx={{ background: theme.palette.common.white }}>
      <Container maxWidth="lg" sx={{ background: theme.palette.common.white }}>
        <Toolbar disableGutters>
          <Box marginRight={1}>
            <Link href="/" underline="none">
              <Typography variant="h2">CAREER Navigator</Typography>
            </Link>
          </Box>

          <Stack
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'flex' },
            }}
            alignItems="start"
            justifyContent="start"
            direction="row"
          />

          <Box sx={{ flexGrow: 0 }}>
            <Stack direction="row" spacing={2}>
              <Button onClick={() => navigate('/login')} color="primary">
                <Typography variant="body1">로그인</Typography>
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

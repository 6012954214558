import { Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useTheme } from '@mui/material';

interface Props {
  name: string;
  price: number;
  description: string;
  onClick?: () => void;
}
export default function PassCard(props: Props) {
  const { name, price, description, onClick } = props;

  const theme = useTheme();

  return (
    <Card
      sx={{
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <CardActionArea onClick={onClick} sx={{ p: 2, border: 1, borderColor: 'rgb(75, 190, 201, 0.3)' }}>
        <CardMedia component="div">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack spacing={1}>
              <Typography variant="h2" color={theme.palette.grey[700]} sx={{ whiteSpace: 'pre' }}>
                {`🎁  ${name}`}
              </Typography>
              <Typography variant="h3" color={theme.palette.grey[400]} sx={{ whiteSpace: 'pre' }}>
                {`💵  ${price.toLocaleString('en-US')} 원`}
              </Typography>
            </Stack>
          </Stack>
        </CardMedia>
        <CardContent>
          <Typography
            variant="body1"
            color={theme.palette.grey[600]}
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
            lineHeight={1.5}
            height="150px"
            sx={{ wordBreak: 'keep-all', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { get } from 'lodash';
import { ReactNode, useMemo } from 'react';

import { UserCoverLetterListItem } from '../../models';
import { fromSemesterType } from '../../util/date-util';

export const getCommonColumnDefs = <T extends UserCoverLetterListItem>(): CoverLetterColumnDef<T>[] => [
  {
    headerName: '기업명',
    field: 'recruitment.company.korName',
    valueGetter: ({ row }) => (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box
          width="40px"
          height="40px"
          sx={{
            background: `center / 130% no-repeat url("${row.recruitment.company.ciUri}")`,
          }}
        />
        <Typography>{row.recruitment.company.korName}</Typography>
      </Stack>
    ),
  },
  {
    headerName: '업계',
    width: 80,
    field: 'recruitment.company.industry',
  },
  {
    headerName: '업종',
    width: 80,
    field: 'recruitment.company.type',
  },
  {
    headerName: '연도',
    width: 80,
    field: 'recruitment.year',
    valueGetter: ({ row }) => `${row.recruitment.year}년`,
  },
  {
    headerName: '반기',
    width: 80,
    field: 'recruitment.semester',
    valueGetter: ({ row }) => `${fromSemesterType(row.recruitment.semester)}`,
  },
  {
    headerName: '고용형태',
    width: 120,
    field: 'recruitment.employeeType',
    valueGetter: ({ row }) => (
      <Stack spacing={0.5}>
        <Typography variant="inherit">{row.recruitment.employeeType}</Typography>
        {row.recruitment.separation && (
          <Typography variant="caption">{row.recruitment.separation.join(', ')}</Typography>
        )}
      </Stack>
    ),
  },
];

export interface CoverLetterColumnDef<T> {
  headerName: string;
  field: string;
  width?: number;
  flex?: number;
  valueGetter?: (params: { row: T }) => ReactNode;
}

interface Props<T> {
  columnDefs?: CoverLetterColumnDef<T>[];
  rows: T[];
  getRowId?: (item: T) => string | number;
  onRowClick?: (item: T) => void;
}

export default function CoverLetterTable<T>(props: Props<T>) {
  const { columnDefs = [], rows, getRowId, onRowClick } = props;

  const tableHeaders = useMemo(
    () => columnDefs.map((columnDef) => (
      <TableCell align="left" key={`id-${columnDef.field}`} sx={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}>
        <Typography variant="h3" component="span">
          {columnDef.headerName}
        </Typography>
      </TableCell>
    )),
    [columnDefs],
  );

  const tableRows = useMemo(
    () => rows.map((row, index) => (
      <TableRow
        key={`cl-row-${getRowId ? getRowId(row) : index}`}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          cursor: 'pointer',
        }}
        hover
        onClick={() => {
          if (onRowClick) onRowClick(row);
        }}
      >
        {columnDefs.map((columnDef) => {
          const content = columnDef.valueGetter ? columnDef.valueGetter({ row }) : get(row, columnDef.field);
          return (
            <TableCell key={columnDef.field} align="left" width={columnDef.width} sx={{ wordBreak: 'keep-all' }}>
              <Typography variant="body1" component="span">
                {content}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    )),
    [columnDefs, getRowId, onRowClick, rows],
  );

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>{tableHeaders}</TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

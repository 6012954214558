import { SemesterType } from './recruitment.model';

export enum RequestStatus {
  IDLE = 'idle',
  REQUESTED = 'requested',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

// interface MyCoverLetterResponse extends PaginationFields {
//   content: UserCoverLetter[];
// }

// interface UserHistoryResponse extends PaginationFields {
//   content: {
//     createdAt: Date;
//     content: UserCoverLetter[];
//   };
// }

// export interface CoverLetterTemplateResponse extends PaginationFields {
//   content: CoverLetterTemplate[];
// }

// export interface RecommendationResponse {}

export interface MetaType {
  title: string;
  sectors: {
    title: string;
    companies: string[];
  }[];
}
export const getMetaData = (): MetaType[] => [
  {
    title: '금융권',
    sectors: [
      { title: '은행', companies: ['하나은행', '우리은행', '신한은행', '국민은행'] },
      { title: '증권사', companies: ['한국투자증권', '증권사2', '증권사3', '증권사4'] },
    ],
  },
  { title: '대기업', sectors: [{ title: '반도체', companies: ['삼성전자', 'SK하이닉스'] }] },
  {
    title: '공기업',
    sectors: [
      {
        title: '산업통상자원부',
        companies: ['한국전력공사', '한국가스기술공사', '한국석유공사', '한국지역난방공사'],
      },
      { title: '국토교통부', companies: ['한국공항공사', '주택도시보증공사', '한국도로공사', '인천국제공항공사'] },
    ],
  },
  {
    title: '금융공기업',
    sectors: [{ title: '은행', companies: ['코스콤', '한국거래소', '신용보증기금', '한국산업은행'] }],
  },
  {
    title: '중견기업',
    sectors: [
      { title: '컨설팅', companies: ['커리어몬스터', '커리어마스터', '커리어워리어', '커리어쩌리'] },
      { title: 'IT', companies: ['팬뗴이션', '팬마스터', '마스터기술', '소프트맥스'] },
    ],
  },
];

export interface CoverLetterItem {
  id: number;
  company: string;
  industry: string;
  sector: string;
  duty: string;
  year: number;
  semester: SemesterType;
  comment?: string;
  updatedAt?: Date;
  viewedAt?: Date;
  logo?: string;
}

export * from './announcement.model';
export * from './cover-letter.model';
export * from './customer.model';
export * from './error.model';
export * from './faq-content.model';
export * from './industry.model';
export * from './recruitment.model';

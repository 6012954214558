import { Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { UserTag } from '../../models';

interface Props {
  tags: UserTag[];
  logo: string;
  company: string;
  description: string;
}

export default function EditorPickCard(props: Props) {
  const { tags, logo, company, description } = props;

  const theme = useTheme();

  const tagString = useMemo(
    () => {
      const MAX_DISPLAYED_TAG = 10;
      const displayedTags = tags.slice(0, MAX_DISPLAYED_TAG);

      return (
        <Typography
          variant="body2"
          color={theme.palette.grey[500]}
          paddingY={1.5}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '3',
          }}
          height="78px"
        >
          {displayedTags.flatMap((tag, index) => {
            if (index === displayedTags.length - 1) {
              return [
                <Typography key={tag.id} variant="inherit" component="span">
                  {`${tag.value}${tags.length > MAX_DISPLAYED_TAG ? '...' : ''}`}
                </Typography>,
              ];
            }

            return [
              <Typography key={tag.id} variant="inherit" component="span">
                {tag.value}
              </Typography>,
              <Typography key={`${tag.id}-and`} variant="inherit" component="span" color={theme.palette.grey[300]}>
              &nbsp;•&nbsp;
              </Typography>,
            ];
          })}
        </Typography>
      );
    },
    [tags, theme],
  );

  return (
    <Card sx={{ cursor: 'pointer', height: '100%' }}>
      <CardActionArea>
        <CardMedia component="div">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              width="120px"
              height="120px"
              sx={{
                background: `center / 130% no-repeat url("${logo}")`,
              }}
            />
            <Typography variant="h2">{company}</Typography>
          </Stack>
        </CardMedia>
        <CardContent>
          <Typography
            variant="h2"
            color={theme.palette.grey[600]}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
            }}
            lineHeight={1.5}
            height="60px"
            sx={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
          >
            {description}
          </Typography>

          {tagString}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

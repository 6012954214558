// 타입 정의
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import ListItem from '@mui/material/ListItem';
import { ContentLayout, MainLayout } from '../../common/style';
import CommonModal from '../../common/modal/common-modal';
import CheckoutPage from '../checkout';
import { Pass } from '../../models/pass.model';
import { getPass } from '../../api/pass';

const PlansContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // 중앙 정렬로 변경
  gap: theme.spacing(5), // 카드 사이의 간격 추가
  flexWrap: 'wrap', // 필요시 줄바꿈 허용
  marginTop: theme.spacing(5),
}));

const PlanCard = styled(Paper)(({ theme }) => ({
  flex: '1 1 300px', // 최소 너비 300px, 남은 공간 균등 분배
  minWidth: '300px',
  maxWidth: '400px', // 최대 너비를 50%로 제한 (간격 고려)
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const PlanName = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  textAlign: 'left',
  margin: theme.spacing(5, 0, 0, 0),
}));

const PlanDescription = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  margin: theme.spacing(5, 0, 0, 0),
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  textAlign: 'left',
  margin: theme.spacing(5, 0, 0, 0),
}));

const FeatureList = styled(List)(({ theme }) => ({
  listStyle: 'none',
  margin: theme.spacing(5, 0, 0, 0),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  padding: theme.spacing(0.5, 1, 0.5, 2.5), // 왼쪽 패딩 조금 더 추가
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '20px', // SVG 크기에 맞게 조정
    height: '20px', // SVG 크기에 맞게 조정
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 18 18\'%3E%3Cpath clip-rule=\'evenodd\' d=\'m14.3838 3.3276c.3164.26739.3561.7406.0887 1.05695l-7.77602 9.20005c-.15078.1783-.37607.2763-.60937.2649s-.44798-.1308-.58067-.323l-3.024-4.38099c-.2353-.34089-.1497-.80799.19119-1.04329.34088-.2353.80798-.14971 1.04328.19118l2.46696 3.5739 7.14303-8.45104c.2674-.31635.7406-.35604 1.0569-.08866z\' fill=\'%23007bff\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '& > *': { // Markdown 컴포넌트를 위한 스타일
    margin: 0,
  },
}));

// const StyledListItem = styled(ListItem)(({ theme }) => ({
//   display: 'list-item', // 기본 리스트 아이템 표시 방식으로 변경
//   padding: theme.spacing(1, 1), // 상하 패딩 줄임
//   marginBottom: 0,
//   '& > *': { // Markdown 컴포넌트를 위한 스타일
//     margin: 0,
//   },
// }));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 3), // 15px, 30px
  fontSize: '18px',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  // marginTop: theme.spacing(1), // 20px
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  margin: theme.spacing(5, 0, 0, 0),
}));

interface PlanCardProps extends Pass {
  onCardClick: (item: Pass) => void;
}

function PlanCardComponent({
  id,
  name,
  price,
  features,
  description,
  onCardClick,
}: PlanCardProps) {
  return (
    <PlanCard>
      <PlanName>{`🎁  ${name}`}</PlanName>
      <PlanDescription>{description}</PlanDescription>
      <PlanPrice>{`💵 ${price.toLocaleString('en-US')} 원`}</PlanPrice>
      <CTAButton onClick={() => onCardClick({
        id,
        name,
        price,
        features,
        description,
      })}
      >
        구매하기
      </CTAButton>
      <FeatureList>
        {features?.map((feature) => (
          <StyledListItem key={feature} disablePadding>
            <Markdown>{feature}</Markdown>
          </StyledListItem>
        ))}
      </FeatureList>

    </PlanCard>
  );
}

export default function PlanComparisonTable() {
  const [passItems, setPassItems] = useState<Pass[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Pass>();
  // FIXME: coupon 가져오는 api를 만들고 coupon을 꽂아넣도록 하기. 그리고 이를 CheckoutPage에 넣기.
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCardClick = (item: Pass) => {
    setSelectedItem(item);
    openModal();
  };

  useEffect(() => {
    (async () => {
      const data = await getPass();
      setPassItems(data);
    })();
  }, []);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack direction="row" alignItems="center" paddingBottom={7}>
          <Typography variant="subtitle2" flex={1} textAlign="center">
            이용권 구매
          </Typography>
        </Stack>
        <PlansContainer>
          {passItems?.map((plan) => (
            <PlanCardComponent
              key={`plan-id-${plan.id}`}
              id={plan.id}
              name={plan.name}
              price={plan.price}
              features={plan.features}
              description={plan.description}
              onCardClick={handleCardClick}
            />
          ))}
        </PlansContainer>
      </ContentLayout>
      <CommonModal
        open={isModalOpen}
        onClose={closeModal}
        title="결제"
        content={
          selectedItem
            ? (
              <CheckoutPage
                name={selectedItem?.name}
                price={selectedItem?.price}
              />
            )
            : <div>Loading...</div>
        }
      />
    </MainLayout>
  );
}

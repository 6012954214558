import { Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { searchUserTags } from '../../../api/meta';
import { MAX_NUM_OF_USER_TAGS } from '../../../common/constant';
import { UserTag } from '../../../models';

interface Props {
  title: string;
  category: string;
  ownedTags: UserTag[];
  changeTag: (item: UserTag, owned: boolean) => void;
}

export default function EditProfileTags(props: Props) {
  const {
    title, category, ownedTags, changeTag,
  } = props;

  const [categoryUserTags, setCategoryUserTags] = useState<UserTag[]>([]);

  useEffect(() => {
    (async () => {
      // FIXME
      const data = await searchUserTags({ category, size: MAX_NUM_OF_USER_TAGS });
      if (data.empty) {
        return;
      }

      setCategoryUserTags(data.content);
    })();
  }, [category]);

  return (
    <Stack spacing={2} marginBottom={2}>
      <Typography variant="h3">
        {title}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        columnGap={1}
        rowGap={1}
        flexWrap="wrap"
      >
        {categoryUserTags.map((item) => {
          const owned = !!ownedTags.find((tag) => tag.id === item.id);

          return (
            <Chip
              key={`financial-item-${item.id}`}
              clickable
              label={item.value}
              variant={owned ? 'filled' : 'outlined'}
              color={owned ? 'primary' : 'default'}
              onClick={() => changeTag(item, owned)}
              sx={(theme) => ({ color: owned ? theme.palette.grey[900] : undefined })}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

import {
  AccountCircle,
  Assignment,
  ChevronLeft,
  ChevronRight,
  Create,
  Dashboard,
  LocalOffer,
  Logout,
  Star as StarIcon,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { PropsWithChildren, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext';
import DocumentScannerIcon from '../../assets/document_scanner.svg';
import RecommendIcon from '../../assets/recommend_icon.svg';
import eventBus from '../../api/event-bus';

const drawerWidth = 220;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  width: open ? `calc(100vw - ${drawerWidth}px);` : '100%',
  minHeight: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props: PropsWithChildren) {
  const { setMenuOpen, menuOpen } = useContext(AppContext);

  const { children } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const tryLogout = () => {
    // todo logout call
    eventBus.emit('logout', '로그아웃 되었습니다.');
  };

  return (
    <Box sx={{ display: 'flex', padding: 0 }}>
      <AppBar position="fixed" open={menuOpen}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(menuOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" noWrap component="div">
            CAREER Navigator
          </Typography>
          <Stack
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'flex' },
            }}
            alignItems="start"
            justifyContent="start"
            direction="row"
          />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={menuOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/home')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/pick')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <img src={RecommendIcon} alt="합격자 Pick!" />
              </ListItemIcon>
              <ListItemText primary="합격자 Pick!" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/passed')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <img src={DocumentScannerIcon} alt="합격 자소서" />
              </ListItemIcon>
              <ListItemText primary="합격 자소서" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/favorites')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="즐겨찾기" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/my-cover-letter/new')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <Create />
              </ListItemIcon>
              <Typography variant="body1">작성하기</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/my-cover-letter')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <Typography variant="body1">내 자소서</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/my-page')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <Typography variant="body1">내 정보</Typography>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding> */}
          {/*   <ListItemButton onClick={() => navigate('/pass')} sx={{ height: '48px' }}> */}
          {/*     <ListItemIcon> */}
          {/*       <LocalOffer /> */}
          {/*     </ListItemIcon> */}
          {/*     <Typography variant="body1">이용권 구매</Typography> */}
          {/*   </ListItemButton> */}
          {/* </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/plan')} sx={{ height: '48px' }}>
              <ListItemIcon>
                <LocalOffer />
              </ListItemIcon>
              <Typography variant="body1">이용권 구매</Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={tryLogout} sx={{ height: '48px' }}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <Typography variant="body1">로그아웃</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={menuOpen}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

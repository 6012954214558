import axios, { AxiosError, AxiosInstance } from 'axios';
import config from '../config';
import { ErrorResponse, ServerError } from '../models';
import eventBus from './event-bus';

const axiosInstances: { [key: string]: AxiosInstance } = {};
const apiClient = (): AxiosInstance => {
  let client = axiosInstances.base;
  if (!client) {
    client = axios.create({
      baseURL: `${config.baseApiUrl}`,
      responseType: 'json',
      timeout: 10000,
      withCredentials: true,
    });
    axiosInstances.base = client;
  }

  return client;
};

export const handleError = (error: unknown) => {
  let code = 500;
  let msg = '서버 에러가 발생했습니다.';
  if (axios.isAxiosError(error)) {
    const axiosErr = error as AxiosError;
    if (axiosErr.response) {
      code = axiosErr.response.status;
      if (axiosErr.response.data && (axiosErr.response.data as ErrorResponse).message) {
        msg = (axiosErr.response.data as ErrorResponse).message;
      }

      if (code === 401) {
        eventBus.emit('logout', '인증이 만료되었습니다. 다시 로그인해주세요.');
        msg = '인증이 만료되었습니다. 다시 로그인해주세요.';
      }
    }
  }

  return new ServerError({ code, msg });
};

export default apiClient;

import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { getStatusOfUser, getUser, updateProfiles } from '../../../api/user';
import AppContext from '../../../AppContext';
import theme from '../../../common/theme';
import { activityCategories, UserTag } from '../../../models';
import EditProfileTags from './edit-profile-tags';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function EditActivityDialog(props: Props) {
  const { open, onClose } = props;

  const { customer, setCustomer, setUserStatus } = useContext(AppContext);

  const [userTags, setUserTags] = useState<UserTag[]>(customer?.userProfile?.profileTags ?? []);

  const changeTag = (tag: UserTag, owned: boolean) => {
    if (owned) {
      setUserTags((prev) => prev.filter((t) => t.id !== tag.id));
    } else {
      setUserTags((prev) => prev.concat(tag));
    }
  };

  const saveProfile = async () => {
    if (!customer) {
      return;
    }

    await updateProfiles(customer.publicId, userTags);

    // refresh user and status
    setCustomer(await getUser(customer.publicId));
    setUserStatus(await getStatusOfUser(customer.publicId));

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h2" flex={1} textAlign="left">
            대외활동과 교내경험을 입력해주세요.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 4 }}>
        {activityCategories.map((category) => (
          <EditProfileTags
            key={category}
            title={category}
            category={category}
            ownedTags={userTags}
            changeTag={changeTag}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ width: '120px' }} onClick={saveProfile}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Stack, Typography, useTheme } from '@mui/material';

import { ContentLayout } from '../../common/style';

export default function AppealSectionView() {
  const theme = useTheme();
  return (
    <ContentLayout sx={{ marginX: '56px', alignItems: 'center' }}>
      <Stack
        sx={{
          marginX: {
            xs: '0px',
            sm: '6px',
            md: '24px',
          },
        }}
        spacing={8}
        paddingY="100px"
      >
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: {
              xs: '56px',
              sm: '84px',
              md: '84px',
            },
          }}
        >
          커리어 네비게이터와 함께
          <br />
          출근하는 아침을 만들어봐요!
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            sm: 'column',
            md: 'row',
          }}
          spacing={5}
          alignItems="center"
          justifyContent="center"
        >
          <Stack alignItems="center" flex={1}>
            <Typography variant="subtitle1" color={theme.palette.primary.main} gutterBottom>
              1,000명+
            </Typography>
            <Typography>
              <b>누적 합격생</b>
            </Typography>
          </Stack>
          <Stack alignItems="center" flex={1}>
            <Typography variant="subtitle1" color={theme.palette.primary.main} gutterBottom>
              382개
            </Typography>
            <Typography>
              <b>누적 합격회사</b>
            </Typography>
          </Stack>
          <Stack alignItems="center" flex={1}>
            <Typography variant="subtitle1" color={theme.palette.primary.main} gutterBottom>
              128,420건
            </Typography>
            <Typography>
              <b>합격 자소서 및 면접후기</b>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ContentLayout>
  );
}

import { CoverLetter, CoverLetterListItem } from '../models';
import apiClient, { handleError } from './api-client';
import { PageResponse } from './util';

export interface CoverLetterSearchParams {
  industry?: string;
  type?: string;
  company?: string;
  page: number;
  size: number;
}

export const getCoverLetterTemplateList = async (params: CoverLetterSearchParams) => {
  try {
    const response = await apiClient().get<PageResponse<CoverLetterListItem>>('/cover-letters', { params });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getCoverLetter = async (coverLetterId: number) => {
  try {
    const response = await apiClient().get<CoverLetter>(`/cover-letters/${coverLetterId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

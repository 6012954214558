import { DateTime } from 'luxon';

import { SemesterType } from '../models';

export const toRelativeDateString = (date: Date) => {
  const dateTime = DateTime.fromJSDate(date);

  if (dateTime.hasSame(DateTime.now(), 'day')) {
    return dateTime.toRelative();
  }
  return dateTime.toFormat('yyyy-MM-dd hh:mm:dd');
};

export const fromSemesterType = (type: SemesterType) => {
  switch (type) {
    case SemesterType.FirstHalf:
      return '상반기';
    case SemesterType.SecondHalf:
      return '하반기';
    case SemesterType.Occasional:
      return '상시';
    default:
      return '';
  }
};

import 'swiper/css';

import { Chip, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../AppContext';
import { getEditorsPick } from '../../api/user';
import HeaderImg from '../../assets/recommendation_header.jpg';
import { ContentLayout, MainLayout } from '../../common/style';
import { UserCoverLetterListItem } from '../../models';
import EditorPickCard from '../home/editor-pick-card';
import { usePurchaseModals } from '../../common/modal/purchase-modal';

export default function RecommendationPage() {
  const { customer } = useContext(AppContext);

  const [contents, setContents] = useState<Record<string, UserCoverLetterListItem[]>>({});
  const { handleCardClick, PurchaseModalsComponent } = usePurchaseModals(customer);

  useEffect(() => {
    (async () => {
      const data = await getEditorsPick();

      setContents(data);
    })();
  }, []);

  const theme = useTheme();

  return (
    <MainLayout>
      <Stack
        sx={{
          width: '100%',
          background: `center / auto 100% no-repeat url(${HeaderImg})`,
          backgroundSize: 'cover',
          overflow: 'clip',
          height: '240px',
        }}
      />
      <ContentLayout>
        <Stack paddingX={6}>
          <Typography variant="subtitle2" fontWeight={600} letterSpacing="-1.2px" marginBottom={2}>
            {customer?.name}
            &nbsp;님께 추천해드려요!
          </Typography>

          <Stack direction="row" flexWrap="wrap" spacing={2} marginBottom={4}>
            {Object.keys(contents).map((section) => (
              <Chip
                key={section}
                label={`#${section}`}
                variant="outlined"
                color="primary"
                sx={{ color: theme.palette.grey[500] }}
                clickable
                onClick={() => {
                  window.location.hash = `section-${encodeURI(section)}`;
                }}
              />
            ))}
          </Stack>

          <Stack spacing={6}>
            {Object.entries(contents).map(([section, articles]) => (
              <Stack id={`section-${section}`} key={section} spacing={4} sx={{ flexGrow: 1 }}>
                <Stack spacing={1.5}>
                  <Typography variant="h1" color={theme.palette.grey[600]} paddingLeft={2}>
                    {section}
                  </Typography>
                  <Divider color={theme.palette.grey[50]} />
                </Stack>
                <Grid container spacing={4}>
                  {articles.map((content) => (
                    <Grid
                      item
                      md={4}
                      key={content.id}
                      onClick={() => handleCardClick(content)}
                    >
                      <EditorPickCard
                        tags={content.profile.profileTags}
                        logo={content.recruitment.company.ciUri}
                        company={content.recruitment.company.korName}
                        description={content.profile.summary}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </ContentLayout>
      <PurchaseModalsComponent />
    </MainLayout>
  );
}

import { handleError } from './api-client';

export const getAnnouncements = async () => {
  try {
    return [
      {
        id: 3,
        title: '[교육안내] ISO 37301 및 ISO 38001 통합인증 심사원 양성과정',
        createdAt: new Date(),
        isFixed: true,
        viewCount: 108,
      },
      {
        id: 2,
        title: '[공지] AA10000 검증기관 자격 획득 안내',
        createdAt: new Date(),
        isFixed: true,
        viewCount: 108,
      },
      {
        id: 1,
        title: '[공지] 컴플라이언스 및 부패방지경영체제 최조 인정',
        createdAt: new Date(),
        isFixed: true,
        viewCount: 108,
      },
    ];
    // const response = await ApiClient().get<[]>('/notices', { params: { } });
    // return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

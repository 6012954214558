import { HelpOutlineOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { keyBy, values } from 'lodash';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import { deleteCoverLetter, getGuideWords, updateCoverLetter } from '../../api/user-cover-letter';
import QuestionWrapper from '../../common/cover-letter/question-wrapper';
import { useCommonSnackbar } from '../../common/hooks';
import SuccessErrorModal from '../../common/modal/success-error-modal';
import StyledSwitch from '../../common/styled-switch';
import { GuideTooltip } from '../../common/tooltip';
import { CoverLetterAnswer, CoverLetterQuestion } from '../../models';
import { GuideWord, UserCoverLetter } from '../../models/cover-letter.model';
import CompanyDetailView from './company-detail-view';
import QuestionEditor from './question-editor';

interface Props {
  userCoverLetter: UserCoverLetter;
}

export default function MyCoverLetterEditor(props: Props) {
  const { userCoverLetter } = props;

  const { customer } = useContext(AppContext);

  const [isCheck, setIsCheck] = useState(false);
  const [answerMap, setAnswerMap] = useState<{ [index: string]: CoverLetterAnswer }>(
    keyBy(
      userCoverLetter.answers.map((answer) => (answer.content ? answer : { ...answer, content: '' })),
      (answer) => answer.questionId,
    ),
  );
  const [guideWords, setGuideWords] = useState<GuideWord[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [saving, setSaving] = useState(false);

  const [snackbar, setFeedback] = useCommonSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isCheck) {
      return;
    }

    (async () => {
      const data = await getGuideWords({
        industry: userCoverLetter.recruitment.company.industry,
      });

      setGuideWords(data.empty ? [] : data.content);
    })();
  }, [isCheck, userCoverLetter]);

  const onModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCheck(event.target.checked);
  };

  const transform = (question: CoverLetterQuestion, answers: { [index: string]: CoverLetterAnswer }) => {
    const questionTags = question.questionTags?.map((tag) => tag.value);
    if (question.isAnswerable) {
      const answer = answers[question.id];
      return [
        <QuestionEditor
          key={question.id}
          tags={questionTags}
          question={question.content}
          answer={answer?.content ?? ''}
          limit={question.limit}
          limitType={question.limitType}
          isParent
          isCheck={isCheck}
          guideWords={guideWords}
          setAnswer={(newAnswer) => {
            setAnswerMap((oldAnswerMap) => {
              const oldAnswer = oldAnswerMap[question.id];
              oldAnswer.content = newAnswer;
              return {
                ...answers,
                [question.id]: oldAnswer,
              };
            });
          }}
        />,
      ];
    }

    const ret = [<QuestionWrapper key={question.id} question={question.content} tags={questionTags} isParent />];

    if (!question.subQuestions) {
      return ret;
    }

    return ret.concat(
      question.subQuestions?.map((subQuestion) => {
        const subAnswer = answers[subQuestion.id];
        return (
          <QuestionEditor
            key={subQuestion.id}
            tags={subQuestion.questionTags?.map((tag) => tag.value)}
            question={subQuestion.content}
            answer={subAnswer?.content ?? ''}
            limit={subQuestion.limit}
            limitType={subQuestion.limitType}
            isParent={false}
            isCheck={isCheck}
            guideWords={guideWords}
            setAnswer={(newAnswer) => {
              setAnswerMap((oldAnswerMap) => {
                const oldAnswer = oldAnswerMap[question.id];
                oldAnswer.content = newAnswer;
                return {
                  ...answers,
                  [question.id]: oldAnswer,
                };
              });
            }}
          />
        );
      }),
    );
  };

  const save = async () => {
    try {
      if (!customer) {
        return;
      }

      setSaving(true);

      await updateCoverLetter(customer.publicId, userCoverLetter.id, { answers: values(answerMap) });

      setFeedback({ message: '입력한 내용이 저장되었습니다.', level: 'success' });
    } catch (error) {
      setFeedback({ message: '저장에 실패했습니다.', level: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const deleteThis = async () => {
    try {
      if (!customer) {
        return;
      }

      await deleteCoverLetter(customer.publicId, userCoverLetter.id);

      navigate('/my-cover-letter');
    } catch (error) {
      //
    } finally {
      setOpenDeletePopup(false);
    }
  };

  useHotkeys<HTMLDivElement>(
    'meta+s',
    () => {
      save();
    },
    { preventDefault: true },
  );
  useHotkeys<HTMLDivElement>(
    'meta+backspace',
    () => {
      setOpenDeletePopup(true);
    },
    { preventDefault: true },
  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CompanyDetailView recruitment={userCoverLetter.recruitment} />
        <Button
          variant="contained"
          onClick={() => {
            navigate({
              pathname: '/passed',
              search: `companyName=${userCoverLetter.recruitment.company.korName}`,
            });
          }}
        >
          {userCoverLetter.recruitment.company.korName}
          &nbsp;합격 자소서 보러가기
        </Button>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        <Typography variant="h3" fontWeight={600}>
          내 자소서 단어체크&nbsp;
          <GuideTooltip title="마우스를 올려 단어 가이드를 확인해보세요." placement="bottom" arrow>
            <HelpOutlineOutlined fontSize="small" sx={{ verticalAlign: 'bottom' }} />
          </GuideTooltip>
        </Typography>

        <StyledSwitch value={isCheck} onChange={onModeChange} inputProps={{ 'aria-label': 'controlled' }} />
      </Stack>

      <Stack spacing={6}>{userCoverLetter.questions.map((question) => transform(question, answerMap))}</Stack>

      <Stack direction="row" justifyContent="center" spacing={3}>
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          sx={{ width: '160px' }}
          onClick={() => setOpenDeletePopup(true)}
        >
          삭제
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          sx={{ width: '160px' }}
          onClick={() => save()}
          disabled={saving}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {saving && <CircularProgress size="1rem" color="inherit" />}
            <span>저장하기</span>
          </Stack>
        </Button>
      </Stack>

      <SuccessErrorModal
        isSuccess={false}
        title="정말 삭제하시겠습니까?"
        description="삭제된 자소서는 복구할 수 없습니다."
        open={openDeletePopup}
        onConfirm={deleteThis}
        onClose={() => setOpenDeletePopup(false)}
      />

      {snackbar}
    </>
  );
}

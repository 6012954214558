interface Config {
  apiHost: string;
  baseApiUrl: string;
  imageAssetUrl: string;
}
const development: Config = {
  apiHost: 'http://localhost:8080',
  baseApiUrl: 'http://localhost:8080/v1',
  imageAssetUrl: 'https://images.careermonster.co.kr/career-navigator/images',
};

const production: Config = {
  apiHost: 'https://cmon-api-dev.careermonster.co.kr',
  baseApiUrl: 'https://cmon-api-dev.careermonster.co.kr/v1',
  imageAssetUrl: 'https://images.careermonster.co.kr/career-navigator/images',
};

const profile = process.env.REACT_APP_PROFILE || 'development';

const configCollections: { [key: string]: Config } = {
  development,
  production,
};

const targetConfig = configCollections[profile];
if (!targetConfig) {
  throw new Error('target config is null');
}

export default targetConfig;

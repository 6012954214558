import { Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import AppealSectionView from './appeal-section';
import FirstGuideSection from './first-guide-section';
import HeroSectionView from './hero-section';

export default function HomeView() {
  const {
    customer,
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (customer) {
      navigate('/home');
    }
  }, [customer, navigate]);

  return (
    <Stack>
      <HeroSectionView />
      <AppealSectionView />
      <FirstGuideSection />
    </Stack>
  );
}

import { groupBy, mapValues, range, uniq } from 'lodash';

import { searchCompany, searchUserTags } from '../api/meta';
import { PageResponse } from '../api/util';
import { MAX_NUM_OF_USER_TAGS } from '../common/constant';
import { UserTag } from '../models';

/* eslint-disable no-await-in-loop */
// FIXME: load from DB
let industryMap: { [key: string]: string[] } = {
  금융: [
    '은행',
    '상호금융/저축은행',
    '증권',
    '자산운용',
    '보험',
    '캐피탈',
    'VC',
    '신탁',
    '보증보험-재단',
    '중앙회/공제회',
    '기관/협회',
  ],
  공기업: [
    '국세청',
    '중앙정부 / 지방자치단체',
    'NGO시민단체',
    '고용보건복지',
    'SOC',
    '농림수산환경',
    '문화예술외교법무',
    '산업진흥정보화',
    '에너지',
    '연구교육',
    '기타',
  ],
  금융공기업: ['은행', '부동산', '기업보증', '자산관리', '투자', '기타'],
  서비스업: [],
  제조업: [],
};

export const getAllYears = () => range(2022, new Date().getFullYear());

export type CategoryOfCompanies = Awaited<ReturnType<typeof getCategoryOfCompanies>>;

export const getCategoryOfCompanies = async () => {
  if (industryMap) {
    return industryMap;
  }

  let page = 0;
  let data = await searchCompany({ page, size: 100 });
  if (data.empty) {
    return {};
  }

  const companies = data.content;
  while (!data.last) {
    page += 1;
    data = await searchCompany({ page, size: 100 });
    if (!data.empty) {
      companies.push(...data.content);
    }
  }

  industryMap = mapValues(
    groupBy(companies, (company) => company.industry),
    (entries) => uniq(entries.map((entry) => entry.type)),
  );

  return industryMap;
};

export const getIndustries = async () => {
  let page = 0;

  const industries = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ category: '업계', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    industries.push(...data.content);
  } while (!data.last);

  return industries;
};

export const getTypes = async (industry?: UserTag) => {
  let page = 0;

  const types = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ parentId: industry?.id, category: '업종', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    types.push(...data.content);
  } while (!data.last);

  return types;
};

export const getGrades = async () => {
  let page = 0;

  const grades = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ category: '학력', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    grades.push(...data.content);
  } while (!data.last);

  return grades;
};

export const getMajors = async () => {
  let page = 0;

  const majors = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ category: '전공', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    majors.push(...data.content);
  } while (!data.last);

  return majors;
};

export const getInstitutions = async () => {
  let page = 0;

  const institutions = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ category: '학교', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    institutions.push(...data.content);
  } while (!data.last);

  return institutions;
};

export const getEmploymentTypes = async () => {
  let page = 0;

  const employmentTypes = [] as UserTag[];
  let data: PageResponse<UserTag>;

  do {
    data = await searchUserTags({ category: '고용형태', size: MAX_NUM_OF_USER_TAGS, page });
    if (data.empty) {
      break;
    }

    page += 1;

    employmentTypes.push(...data.content);
  } while (!data.last);

  return employmentTypes;
};

import { Box, Stack, Typography, useTheme } from '@mui/material';

interface Props {
  logo: string;
  year: string;
  semester: string;
  company: string;
  employeeType: string;
  separation?: string[];
}

export default function CoverLetterTemplateCard(props: Props) {
  const { logo, company, year, semester, employeeType, separation } = props;

  const theme = useTheme();
  return (
    <Stack paddingY={3} sx={{ cursor: 'pointer' }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          width="120px"
          height="120px"
          sx={{
            background: `center / 130% no-repeat url("${logo}")`,
          }}
        />
        <Stack spacing={1}>
          <Typography variant="h2" color={theme.palette.grey[700]}>
            {company}
          </Typography>
          <Typography variant="h3" color={theme.palette.grey[600]}>
            {year}
              &nbsp;
            {semester}
              &nbsp;
            {employeeType}
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[400]}>
            {separation?.join(', ')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { ArrowForwardIosOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { curry } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { object, string, ValidationError } from 'yup';

import { register } from '../../api/user';
import { ServerError } from '../../models';

interface Props {
  onFinished: () => void;
}

enum RequestStatus {
  IDLE = 'idle',
  SENDING = 'sending',
  SENT = 'sent',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export default function RegistrationFormView(props: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const { onFinished } = props;

  const [name, setName] = useState('');
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };
  const [email, setEmail] = useState('');
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const [phone, setPhone] = useState('');
  const [certCode, setCertCode] = useState('');
  const [certCodeStatus, setCertCodeStatus] = useState(RequestStatus.IDLE);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCertCodeStatus(RequestStatus.IDLE);
    setPhone(event.target.value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const handleSendCodeForCertification = () => {
    setCertCodeStatus(RequestStatus.SENDING);
  };

  const [isCertCompleted, setIsCertCompleted] = useState(false);
  const handlePhoneCertification = () => {
    setIsCertCompleted(true);
  };

  const [gender, setGender] = useState<string | null>('female');
  const handleSelectGender = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    setGender(newValue);
  };

  const theme = useTheme();

  useEffect(() => {
    if (certCodeStatus === RequestStatus.SENDING) {
      setTimeout(() => {
        setCertCodeStatus(RequestStatus.SENT);

        setTimeout(() => {
          setCertCodeStatus(RequestStatus.SENT);
        }, 5000);
      }, 1000);
    }
  }, [certCodeStatus]);

  const handleCertCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCertCode(event.target.value);
  };

  const [agreements, setAgreements] = useState([false, false, false]);
  const handleAllAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreements([event.target.checked, event.target.checked, event.target.checked]);
  };

  const handleAgree = curry((index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    agreements[index] = event.target.checked;
    setAgreements([...agreements]);
  });

  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);

  const handleRegistration = async () => {
    try {
      await object()
        .shape({
          name: string().required('이름을 입력 해주세요.').min(1),
          email: string().email('올바른 이메일을 입력 해주세요.').required('올바른 이메일을 입력 해주세요.').min(4),
          password: string().required('8자리 이상의 비밀번호를 입력 해주세요.').min(8).max(24),
          phone: string().required('핸드폰은 필수 값 입니다.'),
        })
        .validate({
          name,
          email,
          password,
          phone,
        });

      setRequestStatus(RequestStatus.SENDING);

      await register(name, email, password, phone);

      onFinished();
    } catch (error) {
      if (error instanceof ServerError) {
        if (error.code === 400) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('가입처리중 서버에러가 발생했습니다.');
        }

        setRequestStatus(RequestStatus.IDLE);
      } else if (error instanceof ValidationError) {
        setErrorMessage(error.errors.join(', '));
      } else {
        setErrorMessage('입력값을 확인해주세요.');
      }
    }
  };

  const isAbleToRegister = useMemo(
    () => isCertCompleted && agreements[0] && agreements[1],
    [isCertCompleted, agreements],
  );

  return (
    <Card style={{ marginTop: 80, padding: '14px 20px' }}>
      <CardHeader title={<Typography variant="h1">회원가입</Typography>} />
      <CardContent>
        <FormControl required>
          <Stack spacing={1} width={{ sm: 420, xs: '100%' }}>
            <Typography variant="body2" align="left" paddingTop={2} gutterBottom>
              이름
              <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <TextField
              value={name}
              onChange={handleNameChange}
              fullWidth
              size="small"
              inputProps={{ maxLength: 20 }}
              color="secondary"
              required
            />
            <Typography align="left" variant="body2" paddingTop={2}>
              이메일
              <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <TextField
              value={email}
              onChange={handleEmailChange}
              fullWidth
              size="small"
              style={{ marginTop: 4 }}
              inputProps={{ maxLength: 40 }}
              color="secondary"
              required
            />

            <Typography align="left" variant="body2" paddingTop={2}>
              비밀번호
              <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <TextField
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              size="small"
              style={{ marginTop: 4 }}
              type="password"
              inputProps={{ maxLength: 24 }}
              placeholder="최소 8자리"
              color="secondary"
              required
            />

            <Typography align="left" variant="body2" paddingTop={2}>
              연락처
              <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                fullWidth
                size="small"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="핸드폰 번호 -없이 입력"
                inputProps={{ maxLength: 11 }}
                color="secondary"
                required
              />
              <Button
                variant="contained"
                sx={{
                  width: '160px',
                  height: '40px',
                  padding: 0,
                }}
                disabled={certCodeStatus === RequestStatus.SENDING || certCodeStatus === RequestStatus.SENT}
                onClick={handleSendCodeForCertification}
              >
                {certCodeStatus === RequestStatus.IDLE && (
                  <Typography variant="body2" color="inherit">
                    인증번호 받기
                  </Typography>
                )}
                {certCodeStatus === RequestStatus.SENDING && <CircularProgress size={20} color="info" />}
                {certCodeStatus === RequestStatus.SENT && (
                  <Typography variant="body2" color="inherit">
                    전송됨
                  </Typography>
                )}
              </Button>
            </Stack>

            {certCodeStatus !== RequestStatus.IDLE && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  fullWidth
                  size="small"
                  value={certCode}
                  onChange={handleCertCodeChange}
                  placeholder="인증 번호"
                  inputProps={{ maxLength: 6 }}
                  required
                />
                <Button
                  variant="contained"
                  sx={{
                    width: '160px',
                    height: '40px',
                    padding: 0,
                  }}
                  onClick={handlePhoneCertification}
                >
                  <Typography variant="body2" color="inherit">
                    인증
                  </Typography>
                </Button>
              </Stack>
            )}

            <Typography align="left" variant="body2" paddingTop={2}>
              성별
              <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>

            <ToggleButtonGroup fullWidth exclusive value={gender} onChange={handleSelectGender} sx={{ height: '40px' }}>
              <ToggleButton value={Gender.FEMALE}>
                <Typography variant="body2" color="inherit">
                  여성
                </Typography>
              </ToggleButton>
              <ToggleButton value={Gender.MALE}>
                <Typography variant="body2" color="inherit">
                  남성
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <FormHelperText error>{errorMessage}</FormHelperText>

            <Stack spacing={2} paddingTop={2}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox checked={agreements.every((agreement) => agreement)} onChange={handleAllAgree} />
                <Typography variant="body1">전체 동의</Typography>
              </Stack>

              <Divider />

              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox checked={agreements[0]} required onChange={handleAgree(0)} />
                <Typography variant="body1" flex={1}>
                  <Typography variant="body2" color={theme.palette.error.main} component="span">
                    (필수)
                  </Typography>
                  &nbsp;커리어 내비게이터 이용약관 동의
                </Typography>

                <Button variant="text" href="/terms/service-policy" target="_blank">
                  <ArrowForwardIosOutlined fontSize="small" color="action" />
                </Button>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox checked={agreements[1]} required onChange={handleAgree(1)} />
                <Typography variant="body1" flex={1}>
                  <Typography variant="body2" color={theme.palette.error.main} component="span">
                    (필수)
                  </Typography>
                  &nbsp;개인정보 수집 및 이용 동의
                </Typography>

                <Button variant="text" href="/terms/information-policy" target="_blank">
                  <ArrowForwardIosOutlined fontSize="small" color="action" />
                </Button>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox checked={agreements[2]} required onChange={handleAgree(2)} />
                <Typography variant="body1">
                  <Typography variant="body2" color={theme.palette.grey[300]} component="span">
                    (선택)
                  </Typography>
                  &nbsp;제3자 마케팅 활용 동의
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" paddingTop={3}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRegistration}
                color="primary"
                disabled={!isAbleToRegister}
              >
                {requestStatus === RequestStatus.IDLE ? (
                  <>회원가입</>
                ) : (
                  <CircularProgress sx={{ color: 'white' }} size={28} />
                )}
              </Button>
            </Stack>
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
}

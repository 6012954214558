import { Chip, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import BackgroundImg from '../../assets/home_first_guide_bg.png';

export default function FirstGuideSection() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack
      sx={{ background: matches ? `center / 100% 100% no-repeat url(${BackgroundImg})` : `center / cover no-repeat url(${BackgroundImg})` }}
      paddingY="28px"
      width="100%"
      direction="row"
      justifyContent="center"
      spacing={4}
    >
      <Typography variant="h2">
        <b>커리어 네비게이터</b>
        &nbsp;
        처음 써봐요!
      </Typography>
      <Chip
        label={(
          <Typography variant="body2" color="white" fontWeight={600}>
            첫 사용 가이드 보기
          </Typography>
        )}
        sx={{ cursor: 'pointer' }}
        color="primary"
      />
    </Stack>
  );
}

import { Box, Typography, useTheme } from '@mui/material';

import ContentLengthChecker from '../../../common/cover-letter/content-length-checker';
import QuestionWrapper from '../../../common/cover-letter/question-wrapper';
import { LimitType } from '../../../models';

interface Props {
  question: string;
  answer: string;
  tags?: string[];
  limit?: number;
  limitType?: LimitType;
  isParent: boolean;
}

export default function QuestionViewer(props: Props) {
  const {
    tags, question, answer, limit, limitType, isParent,
  } = props;

  const theme = useTheme();

  return (
    <QuestionWrapper
      question={question}
      tags={tags}
      isParent={isParent}
    >
      {limit && limitType && (<ContentLengthChecker content={answer} limit={limit} limitType={limitType} />)}
      <Box border={`1px solid ${theme.palette.grey[500]}`} borderRadius={1} minHeight="16rem" width="100%" p={2}>
        <Typography variant="body1" whiteSpace="pre-wrap">{answer}</Typography>
      </Box>
    </QuestionWrapper>
  );
}

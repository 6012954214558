import 'swiper/css';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import { getEditorsPickPreview } from '../../api/user';
import { UserCoverLetterListItem } from '../../models';
import EditorPickCard from './editor-pick-card';
import AppContext from '../../AppContext';
import { usePurchaseModals } from '../../common/modal/purchase-modal';

export default function EditorPick() {
  const [contents, setContents] = useState<Record<string, UserCoverLetterListItem[]>>({});
  const { customer } = useContext(AppContext);
  const { handleCardClick, PurchaseModalsComponent } = usePurchaseModals(customer);

  const swiperRef = useRef<SwiperCore>();

  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const data = await getEditorsPickPreview();
      setContents(data);
    })();
  }, []);

  const [category, picks] = useMemo(() => {
    const firstContents = Object.entries(contents)[0];

    return firstContents ?? ['', []];
  }, [contents]);

  return (
    <Stack alignItems="center" paddingTop={2}>
      <Stack direction="row" width="100%" alignItems="center">
        <Button
          variant="text"
          onClick={() => swiperRef.current?.slidePrev()}
          sx={{
            width: '40px',
            height: '40px',
            minWidth: '0',
          }}
        >
          <ArrowBackIosNew fontSize="small" sx={{ color: theme.palette.grey[500] }} />
        </Button>
        <Typography variant="h3" flex={1} textAlign="center">
          커리어 네비게이터가 준비한 에디터즈 픽!
          <Typography variant="h2" component="span">
            {category ? ` - ${category} 부문` : ''}
          </Typography>
        </Typography>
        <Button
          variant="text"
          onClick={() => swiperRef.current?.slideNext()}
          sx={{
            width: '40px',
            height: '40px',
            minWidth: '0',
          }}
        >
          <ArrowForwardIos fontSize="small" sx={{ color: theme.palette.grey[500] }} />
        </Button>
      </Stack>

      <Stack sx={{ width: '100%' }} marginTop={3}>
        <Swiper
          style={{ width: '100%' }}
          spaceBetween={20}
          grabCursor
          slidesPerView={3}
          slidesPerGroup={3}
          onBeforeInit={(swiper: SwiperCore) => {
            swiperRef.current = swiper;
          }}
        >
          {picks.map((content) => (
            <SwiperSlide
              key={content.id}
              onClick={() => handleCardClick(content)}
            >
              <EditorPickCard
                tags={content.profile.profileTags}
                logo={content.recruitment.company.ciUri}
                company={content.recruitment.company.korName}
                description={content.profile.summary}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Stack alignItems="flex-end">
          <Link underline="none" href="/recommendation">
            <Typography variant="caption" color={theme.palette.grey[300]}>
              추천 자소서 전체보기 &gt;
            </Typography>
          </Link>
        </Stack>
      </Stack>
      <PurchaseModalsComponent />
    </Stack>
  );
}

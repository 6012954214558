import { FavoriteUserCoverLetterListItem } from '../models';
import apiClient, { handleError } from './api-client';
import { PageResponse } from './util';

export const addUserCoverLetterInFavorites = async (publicUserId: string, userCoverLetterId: number) => {
  try {
    await apiClient().post(`/users/${publicUserId}/favorites`, {
      type: 'USER_COVER_LETTER',
      contentId: userCoverLetterId,
    });
  } catch (err) {
    throw handleError(err);
  }
};

export const removeUserCoverLetterInFavorites = async (publicUserId: string, userCoverLetterId: number) => {
  try {
    await apiClient().delete(`/users/${publicUserId}/favorites`, {
      params: {
        type: 'USER_COVER_LETTER',
        contentId: userCoverLetterId,
      },
    });
  } catch (err) {
    throw handleError(err);
  }
};

export interface GetFavoriteUserCoverLetterParams {
  page: number;
  size: number;
  sort?: string[];
}

export const getFavoriteUserCoverLetters = async (publicUserId: string, params: GetFavoriteUserCoverLetterParams) => {
  try {
    const response = await apiClient().get<PageResponse<FavoriteUserCoverLetterListItem>>(`/users/${publicUserId}/favorites`, {
      params,
    });

    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

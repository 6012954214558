import { CheckBoxOutlined } from '@mui/icons-material';
import { createTheme, responsiveFontSizes } from '@mui/material';

const grey = {
  50: '#f0f0f0',
  100: '#e0e0e0',
  200: '#c2c2c2',
  300: '#a3a3a3',
  400: '#858585',
  500: '#666666',
  600: '#4d4d4d',
  700: '#333333',
  800: '#1a1a1a',
  900: '#0a0a0a',
};

const white = '#ffffff';
const black = grey[900];

const themeBase = {
  palette: {
    primary: {
      main: '#4bbec9',
      dark: '#24b1bf',
      light: '#c9ebef',
      contrastText: white,
    },
    secondary: {
      main: '#e66144',
      dark: '#d75942',
      light: '#e26a5a',
      contrastText: white,
    },
    common: {
      black,
      white,
    },
    grey,
    error: {
      main: '#ff0032',
      dark: '#f70032',
      light: '#ff224e',
    },
  },
  typography: {
    fontFamily: 'Pretendard',
    allVariants: { color: grey[900] },
    subtitle1: {
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: '-2.5px',
    },
    subtitle2: {
      fontSize: '2rem',
      fontWeight: 700,
      letterSpacing: '-2.5px',
    },
    h1: {
      fontSize: '1.625rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '-0.5px',
    },
    body2: {
      fontSize: '0.9375rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    overline: {
      fontSize: '0.8125rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.8125rem',
      fontWeight: 500,
    },
  },
};

export default responsiveFontSizes(
  createTheme({
    ...themeBase,
    components: {
      MuiCard: { defaultProps: { elevation: 0 } },
      MuiCheckbox: {
        defaultProps: { checkedIcon: <CheckBoxOutlined /> },
        styleOverrides: { root: { padding: '0 4px 0 0' } },
      },
      MuiButton: {
        styleOverrides: {
          root: { boxShadow: 'none' },
          sizeMedium: ({ theme }) => ({
            fontSize: theme.typography.body2.fontSize,
            paddingTop: '12px',
            paddingBottom: '12px',
          }),
          sizeLarge: ({ theme }) => ({ fontSize: theme.typography.h2.fontSize }),
        },
      },
      MuiTableContainer: { styleOverrides: { root: { boxShadow: 'none' } } },
      MuiTableCell: { styleOverrides: { root: {}, head: { background: grey[50] } } },
      MuiChip: {
        styleOverrides: {
          filledPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
          }),
        },
      },
      MuiDialogContent: { styleOverrides: { root: { padding: '32px' } } },
      MuiDialogActions: { styleOverrides: { root: { padding: '0 32px 32px' } } },
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
    },
  }),
);

import { Edit, Settings } from '@mui/icons-material';
import { Button, Card, ClickAwayListener, Stack, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import AppContext from '../../AppContext';
import PasswordChangeDialog from './dialogs/password-change-dialog';
import PhoneChangeDialog from './dialogs/phone-change-dialog';
import QuitDialog from './dialogs/quit-dialog';

function SettingHomeHeader() {
  const {
    customer,
  } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const onPasswordClose = () => {
    setPasswordChangeOpen(false);
  };
  const onPasswordOpen = () => {
    setPasswordChangeOpen(true);
  };

  const [quitOpen, setQuitOpen] = useState(false);
  const onQuitClose = () => {
    setQuitOpen(false);
  };
  const onQuitOpen = () => {
    setQuitOpen(true);
  };

  const [phoneOpen, setPhoneOpen] = useState(false);
  const onPhoneClose = () => {
    setPhoneOpen(false);
  };
  const onPhoneOpen = () => {
    setPhoneOpen(true);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Stack flex={1} spacing={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h1" flex={1}>
            {customer?.name ?? ''}
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={(
                <Card>
                  <Stack p="16px">
                    <Button sx={{ justifyContent: 'left' }} onClick={onPasswordOpen}>
                      <Typography>비밀번호 변경하기</Typography>
                    </Button>
                    <Button sx={{ justifyContent: 'left' }} onClick={onQuitOpen}>
                      <Typography>탈퇴하기</Typography>
                    </Button>
                  </Stack>
                </Card>
              )}
              PopperProps={{
                disablePortal: true,
                sx: {
                  '& .MuiTooltip-tooltip': {
                    background: 'white',
                    padding: 0,
                    margin: 0,
                  },
                },
              }}
              placement="bottom-end"
            >
              <Settings onClick={handleTooltipOpen} sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </ClickAwayListener>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} paddingTop="16px">
          <Typography variant="caption" width="56px">
            이메일
          </Typography>
          <Typography variant="body1">{customer?.email ?? ''}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="caption" width="56px">
            성별
          </Typography>
          <Typography variant="body1">{customer?.gender ?? ''}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="caption" width="56px">
            생년월일
          </Typography>
          <Typography variant="body1">{customer?.birthday ?? ''}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="caption" width="56px">
            연락처
          </Typography>
          <Typography variant="body1">{customer?.phone ?? ''}</Typography>
          <Edit fontSize="small" sx={{ cursor: 'pointer' }} onClick={onPhoneOpen} />
        </Stack>
      </Stack>
      <PasswordChangeDialog open={passwordChangeOpen} onClose={onPasswordClose} />
      <QuitDialog open={quitOpen} onClose={onQuitClose} />
      <PhoneChangeDialog open={phoneOpen} onClose={onPhoneClose} />
    </Stack>
  );
}

export default SettingHomeHeader;

import { Company, UserTag } from '../models';
import apiClient, { handleError } from './api-client';
import { PageResponse } from './util';

export interface CompanySearchParam {
  korName?: string;
  engName?: string;
  industry?: string;
  type?: string;
  page?: number;
  size?: number;
  sort?: string;
}

export const searchCompany = async (searchParams: CompanySearchParam) => {
  try {
    const response = await apiClient().get<PageResponse<Company>>('/companies', { params: searchParams });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export interface CompanyCreateParam {
  korName: string;
  engName?: string;
  industry?: string;
  type?: string;
  ciUri?: string;
}

export const postCompany = async (params: CompanyCreateParam) => {
  try {
    const response = await apiClient().post('/companies', params);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export interface UserTagSearchParam {
  category?: string;
  parentId?: number;
  value?: string;
  page?: number;
  size?: number;
  sort?: string;
}

export const searchUserTags = async (searchParams: UserTagSearchParam) => {
  try {
    const response = await apiClient().get<PageResponse<UserTag>>('/profile-tags', { params: searchParams });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export interface UserTagCreateParam {
  category: string;
  value: string;
}

export const postUserTag = async (createParams: UserTagCreateParam) => {
  try {
    const response = await apiClient().post<UserTag>('/profile-tags', createParams);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

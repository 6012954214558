import { Alert, Fade, Snackbar } from '@mui/material';
import { useMemo, useState } from 'react';

export interface Feedback {
  level: 'error' | 'success';
  message: string;
}

export function useCommonSnackbar(): [JSX.Element, React.Dispatch<React.SetStateAction<Feedback | undefined>>] {
  const [systemMessage, setFeedback] = useState<Feedback>();

  const snackbar = useMemo(
    () => (
      <Snackbar
        open={!!systemMessage}
        TransitionComponent={Fade}
        onClose={() => setFeedback(undefined)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {systemMessage && (
          <Alert onClose={() => setFeedback(undefined)} severity={systemMessage?.level} sx={{ width: '100%' }}>
            {systemMessage?.message}
          </Alert>
        )}
      </Snackbar>
    ),
    [systemMessage],
  );

  return [snackbar, setFeedback];
}

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { confirmEmail } from '../../api/user';
import { ServerError } from '../../models';

export default function EmailValidationView() {
  const [params] = useSearchParams();
  const code = params.get('code');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    const validateEmail = async () => {
      if (code) {
        const res = await confirmEmail(code);
        if (res instanceof ServerError) {
          setErrMsg('인증 코드가 올바르지 않습니다. 다시 시도해주세요.');
        } else {
          /* empty */
        }
      }
    };

    if (code) {
      validateEmail();
    }
  }, [code]);

  return <Box>{errMsg}</Box>;
}

import { Stack, Typography, useTheme } from '@mui/material';
import iconv from 'iconv-lite';
import { useEffect, useState } from 'react';

interface Props {
  content: string;
  limit: number;
  limitType: '자' | '바이트';
}

const calcByteForEucKr = (str: string) => {
  const buf = iconv.encode(str.replace(/\n/gm, ''), 'euc-kr');
  return buf.length;
};

export default function ContentLengthChecker(props: Props) {
  const { content, limit, limitType } = props;

  const [length, setLength] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    if (limitType === '바이트') {
      setLength(calcByteForEucKr(content));
    } else {
      setLength(content.replace(/\n/gm, '').length);
    }
  }, [content, limit, limitType]);

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Typography variant="body1" color={theme.palette.grey[500]} fontWeight={500}>
        현재&nbsp;
        <Typography
          variant="body1"
          color={length > limit ? theme.palette.warning.main : theme.palette.grey[900]}
          component="span"
        >
          {length}
        </Typography>
        &nbsp;
        {limitType}
        &nbsp;/&nbsp;
        <Typography variant="body1" color={theme.palette.grey[500]} component="span">
          {limit}
        </Typography>
        &nbsp;
        {limitType}
        &nbsp;이내
      </Typography>
    </Stack>
  );
}

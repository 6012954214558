import {
  Company,
  Customer,
  FavoriteCompany,
  JobExperience,
  RecentUserCoverLetterListItem,
  UserCoverLetterListItem,
  UserStatus,
  UserTag,
} from '../models';
import apiClient, { handleError } from './api-client';
import { PageResponse } from './util';

/* export const changePassword = async (currentPassword: string, newPassword: string) => {
  try {
    const response = await apiClient().post<{
      customer: Customer;
      token: string;
    }>('/customers/change-password', {
      currentPassword,
      newPassword,
    });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
}; */

export const resetPassword = async (email: string) => {
  try {
    const response = await apiClient().post<{
      customer: Customer;
      token: string;
    }>('/customers/reset-password', { email });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMemberHash = async (publicUserId: string) => {
  try {
    const response = await apiClient().post<string>('/channel-talk/member-hash', { publicId: publicUserId });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getUser = async (publicUserId: string) => {
  try {
    const response = await apiClient().get<Customer>(`/users/${publicUserId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

/* export const login = async (email: string, password: string) => {
  try {
    const response = await apiClient().post<LoginResponse>('/login', {
      email,
      password,
    });

    const { accessToken, publicId } = response.data;
    const customer = await getUser(publicId, accessToken);

    return {
      customer,
      token: accessToken,
    };
  } catch (err) {
    throw handleError(err);
  }
}; */

export const register = async (name: string, email: string, password: string, phone: string) => {
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('phone', phone);

    await apiClient().post<{}>('/customers/registration', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  } catch (err) {
    throw handleError(err);
  }
};

export const confirmEmail = async (code: string) => {
  try {
    const response = await apiClient().get<{
      result: boolean;
    }>('/customers/email-validation', { params: { code } });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getStatusOfUser = async (publicUserId: string) => {
  try {
    const response = await apiClient().get<UserStatus>(`/users/${publicUserId}/status`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getEditorsPickPreview = async () => {
  try {
    const response = await apiClient().get<Record<string, UserCoverLetterListItem[]>>('/editor-picks/preview');
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getEditorsPick = async () => {
  try {
    const response = await apiClient().get<Record<string, UserCoverLetterListItem[]>>('/editor-picks');
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getPersonalizedRecommendations = async (publicUserId: string) => {
  try {
    const response = await apiClient().get<UserCoverLetterListItem[]>(`/users/${publicUserId}/recommendations`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyCoverLetters = async (publicUserId: string) => {
  try {
    const response = await apiClient().get<PageResponse<RecentUserCoverLetterListItem>>(
      `/users/${publicUserId}/cover-letters`,
    );
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getFavoriteCompanies = async (publicUserId: string) => {
  try {
    const response = await apiClient().get<PageResponse<FavoriteCompany>>(`/users/${publicUserId}/favorite-companies`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const addFavoriteCompanies = async (publicUserId: string, companies: Company[]) => {
  try {
    const response = await apiClient().post(`/users/${publicUserId}/favorite-companies`, {
      companies: companies.map((company) => company.id),
    });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteFavoriteCompanies = async (publicUserId: string, userFavoriteCompanyId: number) => {
  try {
    const response = await apiClient().delete(`/users/${publicUserId}/favorite-companies/${userFavoriteCompanyId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateProfiles = async (publicUserId: string, profiles: UserTag[]) => {
  try {
    const response = await apiClient().put(`/users/${publicUserId}/profile`, {
      profiles: profiles.map((profile) => ({ profileTagId: profile.id })),
    });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateJobExperiences = async (publicUserId: string, jobExperiences: JobExperience[]) => {
  try {
    const response = await apiClient().put(`/users/${publicUserId}/job-experience`, {
      experiences: jobExperiences.map((jobExperience) => ({
        companyId: jobExperience.company.id,
        employeeType: jobExperience.employmentType.id,
      })),
    });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

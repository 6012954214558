import { Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useTheme } from '@mui/material';

import { useContext } from 'react';
import { UserCoverLetterListItem, UserTag } from '../models';
import ProfileTags from './profile-tags';
import { usePurchaseModals } from './modal/purchase-modal';
import AppContext from '../AppContext';

interface Props {
  item: UserCoverLetterListItem;
  myTags: UserTag[];
}

export default function TopPick(props: Props) {
  const { item, myTags } = props;
  const { customer } = useContext(AppContext);
  const { handleCardClick, PurchaseModalsComponent } = usePurchaseModals(customer);

  const theme = useTheme();

  return (
    <Card>
      <CardActionArea>
        <Stack
          direction="row"
          spacing={4}
          onClick={() => handleCardClick(item)}
        >
          <CardMedia sx={{ position: 'relative', border: `1px solid ${theme.palette.grey[50]}`, borderRadius: 1 }}>
            <Box
              position="absolute"
              bgcolor={theme.palette.primary.main}
              p="4px 12px"
              borderRadius={1}
              left="12px"
              top="12px"
            >
              <Typography variant="h3" color={theme.palette.common.white}>
                BEST
              </Typography>
            </Box>
            <Box
              width="200px"
              height="200px"
              margin="0 auto"
              sx={{
                background: `center / 130% no-repeat url("${item.recruitment.company.ciUri}")`,
              }}
            />
          </CardMedia>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h1">{item.recruitment.company.korName}</Typography>
              <Typography variant="h2" paddingBottom={1} color={theme.palette.grey[600]}>
                {item.profile.summary}
              </Typography>
              <ProfileTags givenTags={item.profile.profileTags} myTags={myTags} />
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
      <PurchaseModalsComponent />
    </Card>
  );
}

import { Container, styled } from '@mui/material';

// sm, md, lg
const breakpoints = [768, 992, 1200, 1500];
export const minQuery = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const maxQuery = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const MainLayout = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: minQuery,
}));

export const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ContentLayout = styled(Container)({
  alignSelf: 'center',
  width: '100%',
  marginTop: '96px',
  marginBottom: '96px',
});

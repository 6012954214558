import { Box, Stack, Typography, useTheme } from '@mui/material';
import Recruitment from '../../models/recruitment.model';
import { fromSemesterType } from '../../util/date-util';

interface Props {
  recruitment: Recruitment;
}

export default function CompanyDetailView(props: Props) {
  const { recruitment } = props;
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        width="90px"
        height="90px"
        sx={{
          background: `center / 130% no-repeat url("${recruitment.company.ciUri}")`,
        }}
      />
      <Stack spacing={1}>
        <Typography variant="h1">{recruitment.company.korName}</Typography>
        <Typography variant="h2" color={theme.palette.grey[600]}>
          {recruitment.year}
          년&nbsp;
          {fromSemesterType(recruitment.semester)}
          &nbsp;
          {recruitment.employeeType}
        </Typography>
        {recruitment.separation && (
          <Typography variant="h3" color={theme.palette.grey[500]}>
            {recruitment.separation.join(', ')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

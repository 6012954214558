import { ViewList, Window } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { values } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { searchUserCoverLetters } from '../../api/user-cover-letter';
import CompanySelectBox from '../../common/input/company-select-box';
import { ContentLayout, MainLayout } from '../../common/style';
import { Company, SemesterType, UserCoverLetterListItem } from '../../models';
import { fromSemesterType } from '../../util/date-util';
import { getAllYears, getIndustries, getTypes } from '../../util/meta';
import CoverLetterCard from './cover-letter-card';
import CoverLetterTable, { getCommonColumnDefs } from './cover-letter-table';
import AppContext from '../../AppContext';
import { usePurchaseModals } from '../../common/modal/purchase-modal';

const PAGE_SIZE = 100;

const columnDefs = getCommonColumnDefs<UserCoverLetterListItem>().concat({
  headerName: '한 줄 요약',
  field: 'profile.summary',
});

export default function CoverLetterPage() {
  const {
    customer,
  } = useContext(AppContext);

  const { handleCardClick, PurchaseModalsComponent } = usePurchaseModals(customer);

  const [viewState, setViewState] = useState(0);

  const [type, setType] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');

  const [year, setYear] = useState<string>('');

  const [semester, setSemester] = useState<string>('');

  const [company, setCompany] = useState<Company | null>(null);

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { data: years } = useQuery({ queryKey: ['years'], queryFn: getAllYears, initialData: [] });
  const { data: industries } = useQuery({ queryKey: ['industries'], queryFn: getIndustries, initialData: [] });
  const { data: types } = useQuery({
    queryKey: ['types', industry],
    initialData: [],
    queryFn: async () => {
      const found = industries?.find((tag) => tag.value === industry);
      return getTypes(found);
    },
  });

  const {
    data: userCoverLetterPages,
    fetchNextPage: fetchNextUserCoverLetterPages,
    hasNextPage: hasNextUserCoverLetterPage,
  } = useInfiniteQuery({
    queryKey: ['userCoverLetters', { type, industry, year, semester, company }] as [
      string,
      { industry: string; type: string; year: string; semester: string; company: Company | null },
    ],
    queryFn: async (context) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { type, industry, year, semester, company } = context.queryKey[1];

      const ret = await searchUserCoverLetters({
        pass: true,
        industry: industry === '' ? undefined : industry,
        type: type === '' ? undefined : type,
        company: company?.korName,
        year: year === '' ? undefined : Number(year),
        semester: (semester === '' ? undefined : semester) as SemesterType,
        page: context.pageParam,
        size: PAGE_SIZE,
      });

      return ret;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? null : lastPage.number + 1),
  });

  const companyName = useMemo(() => params.get('companyName'), [params]);

  const userCoverLetters = useMemo(
    () => userCoverLetterPages?.pages.flatMap((e) => (e.empty ? [] : e.content)) ?? [],
    [userCoverLetterPages],
  );

  const theme = useTheme();

  const onIndustryChange = (e: SelectChangeEvent) => {
    setIndustry(e.target.value);
    setType('');
    navigate({
      search: '',
    });
  };

  const onTypeChange = (e: SelectChangeEvent) => {
    setType(e.target.value);
    navigate({
      search: '',
    });
  };

  const onYearChange = (e: SelectChangeEvent) => {
    setYear(e.target.value);
  };

  const onSemesterChange = (e: SelectChangeEvent) => {
    setSemester(e.target.value);
  };

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" paddingBottom={7}>
            <Typography variant="subtitle2" flex={1}>
              합격 자소서
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body1">업계</Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select value={industry} onChange={onIndustryChange} displayEmpty size="small">
                <MenuItem value="">전체</MenuItem>
                {industries.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" paddingLeft="8px">
              업종
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select value={type} onChange={onTypeChange} displayEmpty size="small">
                <MenuItem value="">전체</MenuItem>
                {types.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" paddingLeft="8px">
              조회기간
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select value={year} onChange={onYearChange} displayEmpty size="small">
                <MenuItem value="">전체</MenuItem>
                {years.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <Select value={semester} onChange={onSemesterChange} displayEmpty size="small">
                <MenuItem value="">전체</MenuItem>
                {values(SemesterType).map((item) => (
                  <MenuItem key={item} value={item}>
                    {fromSemesterType(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" paddingLeft="8px">
              회사명
            </Typography>
            <FormControl sx={{ flexGrow: 1 }}>
              <CompanySelectBox
                size="small"
                fullWidth
                industry={industry}
                type={type}
                company={company}
                setCompany={(newValue) => {
                  setCompany(newValue);
                  navigate({
                    search: newValue ? `companyName=${newValue.korName}` : '',
                  });
                }}
                companyName={companyName}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <ButtonGroup variant="outlined" sx={{ color: theme.palette.grey[300] }}>
              <Button color="inherit" onClick={() => setViewState(0)}>
                <Window color={viewState === 0 ? 'primary' : 'inherit'} />
              </Button>
              <Button color="inherit" onClick={() => setViewState(1)}>
                <ViewList color={viewState === 1 ? 'primary' : 'inherit'} />
              </Button>
            </ButtonGroup>
          </Stack>

          <InfiniteScroll
            dataLength={userCoverLetters?.length ?? 0}
            next={() => {
              fetchNextUserCoverLetterPages();
            }}
            hasMore={hasNextUserCoverLetterPage}
            loader={(
              <Box display="flex" paddingY={4} justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            scrollThreshold={0.9}
            hasChildren
          >
            {viewState === 0 && (
              <Grid container spacing={4}>
                {userCoverLetters?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={`g-item-${item.id}`}>
                    <CoverLetterCard
                      company={item.recruitment.company.korName}
                      logo={item.recruitment.company.ciUri}
                      comment={item.profile.summary}
                      year={item.recruitment.year}
                      semester={fromSemesterType(item.recruitment.semester)}
                      employeeType={item.recruitment.employeeType}
                      separation={item.recruitment.separation}
                      onClick={() => handleCardClick(item)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {viewState === 1 && (
              <CoverLetterTable
                rows={userCoverLetters}
                columnDefs={columnDefs}
                getRowId={(item) => item.id}
                onRowClick={(item) => handleCardClick(item)}
              />
            )}
          </InfiniteScroll>
        </Stack>
      </ContentLayout>
      <PurchaseModalsComponent />
    </MainLayout>
  );
}

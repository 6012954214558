import { Stack, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ContentLayout, MainLayout } from '../../../common/style';
import TemplateSelector from './template-selector';

export default function NewCoverLetter() {
  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={3}>
          <Stack spacing={2} paddingBottom={3}>
            <Typography variant="subtitle2">작성하기</Typography>
            <Typography variant="body2" color={(theme) => theme.palette.grey[500]}>
              <InfoOutlinedIcon fontSize="inherit" sx={{ verticalAlign: '-2px' }} />
              &nbsp;아래는 현재 채용을 진행 중인 기업 목록입니다.
            </Typography>
          </Stack>
          <TemplateSelector />
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommonSnackbar } from '../hooks';
import { buyCoverLetter, shouldBuy } from '../../api/user-cover-letter';
import SuccessErrorModal from './success-error-modal';
import { UserCoverLetterListItem } from '../../models';

interface PurchaseModals {
  dialogOpen: boolean;
  passDialogOpen: boolean;
  selectedItem: any | null;
  handleCardClick: (item: any) => Promise<void>;
  PurchaseModalsComponent: React.FC;
}

export const usePurchaseModals = (customer: any): PurchaseModals => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passDialogOpen, setPassDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UserCoverLetterListItem | null>(null);
  const [snackbar, setFeedback] = useCommonSnackbar();
  const navigate = useNavigate();

  const handleBuyPass = async () => {
    navigate('/plan');
  };

  const handleConfirmBuy = async () => {
    if (!customer || !selectedItem) {
      return;
    }

    try {
      await buyCoverLetter(customer.publicId, {
        userCoverLetterId: selectedItem.id,
      });

      navigate(`/cover-letter/${selectedItem.id}`);
    } catch (error) {
      setFeedback({
        level: 'error',
        message: '구매 실패',
      });
    } finally {
      setDialogOpen(false);
      setSelectedItem(null);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };

  const handlePassClose = () => {
    setPassDialogOpen(false);
  };

  const handleCardClick = async (item: any) => {
    if (!customer) {
      return;
    }

    try {
      const shouldBuyResponse = await shouldBuy(customer.publicId, item.id);

      if (shouldBuyResponse === true) {
        setSelectedItem(item);
        setDialogOpen(true);
      } else {
        navigate(`/cover-letter/${item.id}`);
      }
    } catch (error) {
      setPassDialogOpen(true);
    }
  };

  const PurchaseModalsComponent = () => (
    <>
      <SuccessErrorModal
        isSuccess
        title="자소서 열람"
        description="자기소개서를 열람하기 위해서는 이용권 차감이 필요합니다. 횟수권을 차감해 구매하시겠습니까?"
        open={dialogOpen}
        onConfirm={handleConfirmBuy}
        onClose={handleClose}
      />
      <SuccessErrorModal
        isSuccess
        title="이용권 구매"
        description="자기소개서를 열람하기 위해서는 이용권 구매가 필요합니다. 이용권을 구매하시겠습니까?"
        open={passDialogOpen}
        onConfirm={handleBuyPass}
        onClose={handlePassClose}
      />
      {snackbar}
    </>
  );

  return {
    dialogOpen,
    passDialogOpen,
    selectedItem,
    handleCardClick,
    PurchaseModalsComponent,
  };
};

import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function QuitDialog(props: Props) {
  const {
    open, onClose,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2" flex={1} textAlign="left">
          회원탈퇴
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography marginBottom="24px">
          탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입
          탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입탈퇴 관련 안내 및 경고 문구 삽입
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning">
          탈퇴하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppContext from './AppContext';
import { getMemberHash, getStatusOfUser, getUser } from './api/user';
import AuthAppBar from './common/app-bar/auth-app-bar';
import LandingAppBar from './common/app-bar/landing-app-bar';
import FooterView from './common/footer';
import theme from './common/theme';
import { Customer, UserStatus } from './models';
import Navigation from './navigation';
import RequiredProfile from './pages/my-page/required-profiles';
import { getCookie, removeCookie, setCookie } from './util/Cookie';
import eventBus from './api/event-bus';

// Create a client
const queryClient = new QueryClient();
const cookieSetOption = {
  maxAge: 60 * 60,
  path: '/',
  secure: window.location.protocol === 'https',
};

// require('dotenv').config();
// const apiKey = process.env.REACT_APP_API_KEY;

export default function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer>();
  const [userStatus, setUserStatus] = useState<UserStatus>();
  const [menuOpen, setMenuOpen] = useState(true);
  const [openRequiredProfile, setOpenRequiredProfile] = useState<boolean>(false);
  const [memberHash, setMemberHash] = useState<string>();

  const contextValue = useMemo(() => ({
    initialized,
    setInitialized,
    customer,
    setCustomer,
    userStatus,
    setUserStatus,
    memberHash,
    setMemberHash,
    menuOpen,
    setMenuOpen,
  }), [customer, initialized, menuOpen, userStatus, memberHash]);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const publicId = getCookie('uid');
        if (!publicId) {
          return;
        }

        const user = await getUser(publicId);
        setCustomer(user);

        const hash = await getMemberHash(user.publicId);
        setMemberHash(hash);

        const status = await getStatusOfUser(user.publicId);
        setUserStatus(status);
      } catch (error) {
        // error
      } finally {
        setInitialized(true);
      }
    };

    if (!initialized) {
      initialize()
        .then(() => {
        });
    }
  }, [initialized]);

  useEffect(() => {
    ChannelService.loadScript();
    if (customer && memberHash) {
      ChannelService.boot({
        pluginKey: 'cf3588c0-0e9e-4277-b446-3fa9aec3cb34', // fill your plugin key
        memberId: customer.publicId, // fill user's member id
        memberHash: memberHash!!,
        profile: { // fill user's profile
          name: customer.name, // fill user's name
          mobileNumber: customer.phone, // fill user's mobile number
          email: customer.email,
        },
      });
    } else {
      ChannelService.boot({
        pluginKey: 'cf3588c0-0e9e-4277-b446-3fa9aec3cb34', // fill your plugin key
      });
    }
  }, [customer, memberHash]);

  useEffect(() => {
    if (!userStatus) {
      return;
    }

    const skipRequiredProfile = getCookie('skip-required-profile');
    if (skipRequiredProfile === undefined) {
      setOpenRequiredProfile(userStatus.shouldFillMandatory);
    }
  }, [userStatus]);

  useEffect(() => {
    const handleLogout = (message: string) => {
      removeCookie('uid');
      setUserStatus(undefined);
      setMemberHash(undefined);
      setCustomer(undefined);

      if (message) {
        alert(message);
      }
    };

    eventBus.on('logout', handleLogout);

    return () => {
      eventBus.off('logout', handleLogout);
    };
  }, [customer]);

  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={contextValue}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {customer ? (
                <AuthAppBar>
                  <Navigation />
                  <FooterView />
                  <RequiredProfile
                    open={openRequiredProfile}
                    onClose={() => {
                      setOpenRequiredProfile(false);
                      setCookie('skip-required-profile', true, cookieSetOption);
                    }}
                  />
                </AuthAppBar>
              ) : (
                <>
                  <LandingAppBar />
                  <Navigation />
                  <FooterView />
                </>
              )}
            </BrowserRouter>
          </QueryClientProvider>
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
}

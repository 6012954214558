import {
  CoverLetterAnswer,
  GuideWord,
  SemesterType,
  UserCoverLetter,
  UserCoverLetterListItem,
  UserCoverLetterWithFavorite,
} from '../models';
import apiClient, { handleError } from './api-client';
import { PageResponse } from './util';

export interface CoverLetterSearchParams {
  industry?: string;
  type?: string;
  company?: string;
  year?: number;
  semester?: SemesterType;
  pass?: boolean;
  page: number;
  size: number;
}

export interface GuideWordSearchParams {
  industry?: string;
  page?: number;
  size?: number;
}

export const searchUserCoverLetters = async (params: CoverLetterSearchParams) => {
  try {
    const response = await apiClient().get<PageResponse<UserCoverLetterListItem>>('/user-cover-letters', { params });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const shouldBuy = async (publicId: string, userCoverLetterId: number) => {
  try {
    const response = await apiClient().get(`/users/${publicId}/usage-check?userCoverLetterId=${userCoverLetterId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

interface UsePassParams {
  userCoverLetterId: number;
}

export const buyCoverLetter = async (publicUserId: string, params: UsePassParams) => {
  try {
    const response = await apiClient().post(`/users/${publicUserId}/use-pass`, params);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getUserCoverLetter = async (userCoverLetterId: number) => {
  try {
    const response = await apiClient().get<UserCoverLetterWithFavorite>(`/user-cover-letters/${userCoverLetterId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

interface UserCoverLetterCreateParams {
  recruitmentId: number;
  coverLetterId: number;
  answers: Pick<CoverLetterAnswer, 'questionId' | 'content'>[];
  editingStatus: 'TEMP' | 'DONE';
}

export const postCoverLetter = async (publicUserId: string, params: UserCoverLetterCreateParams) => {
  try {
    const response = await apiClient().post<UserCoverLetter>(`/users/${publicUserId}/cover-letters`, params);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyUserCoverLetter = async (publicUserId: string, userCoverLetterId: number) => {
  try {
    const response = await apiClient().get<UserCoverLetter>(`/users/${publicUserId}/cover-letters/${userCoverLetterId}`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

interface UserCoverLetterUpdateParams {
  answers: CoverLetterAnswer[];
}

export const updateCoverLetter = async (
  publicUserId: string,
  userCoverLetterId: number,
  params: UserCoverLetterUpdateParams,
) => {
  try {
    const response = await apiClient().put<UserCoverLetter>(
      `/users/${publicUserId}/cover-letters/${userCoverLetterId}`,
      params,
    );
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteCoverLetter = async (publicUserId: string, userCoverLetterId: number) => {
  try {
    await apiClient().delete(`/users/${publicUserId}/cover-letters/${userCoverLetterId}`);
  } catch (err) {
    throw handleError(err);
  }
};

export const getGuideWords = async (params: GuideWordSearchParams) => {
  try {
    const response = await apiClient().get<PageResponse<GuideWord>>('/guide-words', { params });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

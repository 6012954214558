import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { postCompany } from '../../../api/meta';
import { useCommonSnackbar } from '../../../common/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function RequestCompanyDialog(props: Props) {
  const {
    open, onClose,
  } = props;
  const [newCompanyName, setNewCompanyName] = useState('');

  const [snackbar, setFeedback] = useCommonSnackbar();

  useEffect(() => {
    if (!open) {
      setNewCompanyName('');
    }
  }, [open]);

  const handleRegister = useCallback(async () => {
    if (newCompanyName.length === 0) {
      setFeedback({
        level: 'error',
        message: '회사명은 1글자 이상이어야 합니다.',
      });
      return;
    }

    try {
      await postCompany({
        korName: newCompanyName,
      });

      setFeedback({
        level: 'success',
        message: '등록에 성공했습니다.',
      });

      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      setFeedback({
        level: 'error',
        message: '등록 요청에 실패했습니다.',
      });
    }
  }, [newCompanyName, onClose, setFeedback]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h2" flex={1} textAlign="left">
            새로운 회사를 등록해주세요
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginY={2}>
          <Typography variant="body1">
            근무했던 회사를 찾을 수 없으셨나요?
            <br />
            커리어몬스터에 새로운 회사를 요청해주세요.
          </Typography>
          <Typography variant="caption">
            고객님께서 요청해주신 회사에 대해 커리어몬스터에 검토 후 부가 정보를 넣어서 최대 3영업일 내에 입력해드립니다.
          </Typography>
          <TextField
            placeholder="회사명"
            value={newCompanyName}
            onChange={(e) => {
              setNewCompanyName(e.target.value);
            }}
          />
        </Stack>
        {snackbar}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button variant="contained" onClick={handleRegister}>
          요청하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { keyBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Star, StarOutline } from '@mui/icons-material';
import AppContext from '../../../AppContext';
import { addUserCoverLetterInFavorites, removeUserCoverLetterInFavorites } from '../../../api/favorites';
import { getUserCoverLetter } from '../../../api/user-cover-letter';
import QuestionWrapper from '../../../common/cover-letter/question-wrapper';
import ProfileTags from '../../../common/profile-tags';
import { ContentLayout, MainLayout } from '../../../common/style';
import { CoverLetterAnswer, CoverLetterQuestion, UserCoverLetterWithFavorite } from '../../../models';
import CompanyDetailView from '../../my-cover-letter/company-detail-view';
import QuestionViewer from './question-viewer';

export default function CoverLetterDetailPage() {
  const { customer } = useContext(AppContext);

  const { coverLetterId } = useParams();

  const [userCoverLetter, setUserCoverLetter] = useState<UserCoverLetterWithFavorite>();

  useEffect(() => {
    (async () => {
      const data = await getUserCoverLetter(Number(coverLetterId!));

      setUserCoverLetter(data);
    })();
  }, [coverLetterId]);

  const answerMap = useMemo(() => {
    if (!userCoverLetter) {
      return {};
    }

    return keyBy(userCoverLetter.answers, (answer) => answer.questionId);
  }, [userCoverLetter]);

  const transform = (question: CoverLetterQuestion, answers: { [index: string]: CoverLetterAnswer }) => {
    const questionTags = question.questionTags?.map((tag) => tag.value);
    if (question.isAnswerable) {
      const answer = answers[question.id];
      return [
        <QuestionViewer
          key={question.id}
          tags={questionTags}
          question={question.content}
          answer={answer.content}
          limit={question.limit}
          limitType={question.limitType}
          isParent
        />,
      ];
    }

    const ret = [<QuestionWrapper key={question.id} question={question.content} tags={questionTags} isParent />];
    if (!question.subQuestions) {
      return ret;
    }

    ret.push(
      ...question.subQuestions.map((subQuestion) => {
        const subAnswer = answers[subQuestion.id];
        return (
          <QuestionViewer
            key={subQuestion.id}
            tags={subQuestion.questionTags?.map((tag) => tag.value)}
            question={subQuestion.content}
            answer={subAnswer.content}
            limit={subQuestion.limit}
            limitType={subQuestion.limitType}
            isParent={false}
          />
        );
      }),
    );

    return ret;
  };

  const handleFavorite = useCallback(async () => {
    if (!customer || !userCoverLetter) {
      return;
    }

    if (!userCoverLetter.isFavorite) {
      await addUserCoverLetterInFavorites(customer.publicId, userCoverLetter.id);

      setUserCoverLetter({
        ...userCoverLetter,
        isFavorite: true,
      });
    } else {
      await removeUserCoverLetterInFavorites(customer.publicId, userCoverLetter.id);

      setUserCoverLetter({
        ...userCoverLetter,
        isFavorite: false,
      });
    }
  }, [customer, userCoverLetter]);

  const navigate = useNavigate();

  return (
    <MainLayout>
      <ContentLayout>
        <Stack paddingY={7} spacing={3}>
          <Typography variant="subtitle2" flex={1}>
            합격 자기 소개서
          </Typography>
        </Stack>

        {userCoverLetter && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={7}>
              <Stack direction="row" spacing={2}>
                <CompanyDetailView recruitment={userCoverLetter.recruitment} />

                <Stack justifyContent="center">
                  <IconButton onClick={handleFavorite}>
                    {userCoverLetter.isFavorite ? <Star /> : <StarOutline />}
                  </IconButton>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                onClick={() => {
                  navigate({
                    pathname: '/passed',
                    search: `companyName=${userCoverLetter.recruitment.company.korName}`,
                  });
                }}
              >
                {userCoverLetter.recruitment.company.korName}
                &nbsp;합격 자소서 보러가기
              </Button>
            </Stack>

            <Typography variant="h2" marginBottom={2}>
              {userCoverLetter.profile.summary}
            </Typography>
            <Box marginBottom={5}>
              <ProfileTags
                givenTags={userCoverLetter.profile.profileTags}
                myTags={customer?.userProfile?.profileTags ?? []}
              />
            </Box>

            <Stack spacing={7}>{userCoverLetter.questions.flatMap((question) => transform(question, answerMap))}</Stack>
          </>
        )}
      </ContentLayout>
    </MainLayout>
  );
}

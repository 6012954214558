import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function PhoneChangeDialog(props: Props) {
  const {
    open, onClose,
  } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };
  const [phoneCertAvailable, setPhoneCertAvailable] = useState(false);
  useEffect(() => {
    if (!open) {
      setPhoneNumber('');
      setPhoneCertAvailable(false);
    }
  }, [open]);

  useEffect(() => {
    if (phoneNumber.length === 10 || phoneNumber.length === 11) {
      setPhoneCertAvailable(true);
    } else {
      setPhoneCertAvailable(false);
    }
  }, [phoneNumber]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2" flex={1} textAlign="left">
          연락처 변경
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={0.5} direction="row">
          <TextField
            placeholder="01012345678"
            sx={{ flex: 1 }}
            type="tel"
            inputMode="tel"
            onChange={onPhoneChange}
            value={phoneNumber}
          />
          <Button variant="contained" disabled={!phoneCertAvailable} sx={{ color: phoneCertAvailable ? 'white' : '#C2C2C2' }}>
            인증번호 받기
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button variant="contained">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

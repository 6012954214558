import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const GuideDetailTooltip = styled(({
  className, ...props
}: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({
  theme,
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    maxWidth: '320px',
    color: theme.palette.grey[900],
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '8px 16px',
    ...theme.typography.body2,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '::before': { border: `1px solid ${theme.palette.primary.main}` },
  },
}));

export const GuideTooltip = styled(({
  className, ...props
}: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({
  theme,
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[900],
  },
  [`& .${tooltipClasses.arrow}`]: { color: theme.palette.grey[100] },
}));

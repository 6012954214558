import { Stack } from '@mui/material';

import QuestionWrapper from '../../../common/cover-letter/question-wrapper';
import { CoverLetter, CoverLetterQuestion } from '../../../models';
import CompanyDetailView from '../company-detail-view';

interface Props {
  coverLetter: CoverLetter;
}

export default function TemplateViewer(props: Props) {
  const { coverLetter } = props;

  const transform = (question: CoverLetterQuestion) => {
    const questionTags = question.questionTags?.map((tag) => tag.value);
    if (question.isAnswerable) {
      return [
        <QuestionWrapper
          key={question.id}
          question={`${question.content} (${question.limit} ${question.limitType})`}
          tags={questionTags}
          isParent
        />,
      ];
    }

    const ret = [<QuestionWrapper key={question.id} question={question.content} tags={questionTags} isParent />];

    if (!question.subQuestions) {
      return ret;
    }

    return ret.concat(
      question.subQuestions.map((subQuestion) => (
        <QuestionWrapper
          key={subQuestion.id}
          question={`${subQuestion.content} (${subQuestion.limit} ${subQuestion.limitType})`}
          tags={subQuestion.questionTags?.map((tag) => tag.value)}
          isParent={false}
        />
      )),
    );
  };

  return (
    <Stack spacing={3}>
      <CompanyDetailView recruitment={coverLetter.recruitment} />
      <Stack spacing={6}>{coverLetter.questions.map((question) => transform(question))}</Stack>
    </Stack>
  );
}

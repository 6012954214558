import apiClient, { handleError } from './api-client';
import { Pass } from '../models/pass.model';

export const getPass = async () => {
  try {
    const response = await apiClient().get<Pass[]>('/pass');
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

import { Close, ExpandMore as ExpandMoreIcon, Search } from '@mui/icons-material';
import {
  Button,
  Card,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  Link,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { filter } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';

import { getAnnouncements } from '../../api/notice';
import { ContentLayout, MainLayout } from '../../common/style';
import { Announcement, FaqCategory, FaqContent } from '../../models';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const {
    expand, ...other
  } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...other} />;
})(({
  theme, expand,
}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
}));

function FaqPage() {
  const theme = useTheme();
  const [announcements, setAnnouncements] = useState<Announcement[]>();
  const [searchText, setSearchText] = useState('');
  const [contents, setContents] = useState<FaqCategory[]>([]);
  const [searchedContents, setSearchedContents] = useState<FaqContent[]>([]);
  const [extendedContents, setExtendedContents] = useState<FaqContent[]>();

  const onExpandClick = (content: FaqContent) => {
    if (extendedContents?.includes(content)) {
      setExtendedContents(filter(extendedContents, (item) => item.question !== content.question));
    } else {
      setExtendedContents([...(extendedContents || []), content]);
    }
  };

  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onClearClick = () => {
    if (searchText) {
      setSearchText('');
    }
  };

  useEffect(() => {
    if (searchText && contents.length !== 0) {
      const filtered: FaqContent[] = [];
      const filteringText = searchText.split(' ').filter((i) => i);
      contents.forEach((item) => {
        item.contents.forEach((content) => {
          if (filteringText.every((el) => content.question.includes(el) || content.answer.includes(el))) {
            filtered.push(content);
          }
        });
      });
      setSearchedContents(filtered);
    }
  }, [searchText, contents]);

  useEffect(() => {
    getAnnouncements()
      .then((newAnnouncements) => {
        setAnnouncements(newAnnouncements);
      });

    setContents([
      {
        title: '추천받기',
        contents: [
          {
            question: '추천받기는 어떻게 사용하나요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 사용하기 위해 필요한 정보는 어떤건가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 이용하려면 결제가 필요한가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
        ],
      },
      {
        title: '즐겨찾기',
        contents: [
          {
            question: '추천받기는 어떻게 사용하나요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 사용하기 위해 필요한 정보는 어떤건가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 이용하려면 결제가 필요한가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
        ],
      },
      {
        title: '멤버쉽',
        contents: [
          {
            question: '멤버쉽은 어떤게 있나요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 사용하기 위해 필요한 정보는 어떤건가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
          {
            question: '추천받기를 이용하려면 결제가 필요한가요?',
            answer: '“추천받기”를 통해 케이스마다 다른 합격 자기소개서를 확인할 수 있어요.',
          },
        ],
      },
    ]);
  }, []);

  return (
    <MainLayout>
      <Stack sx={{ background: theme.palette.primary.dark, width: '100%' }}>
        <ContentLayout>
          <Stack p="56px">
            <Stack direction="row" alignItems="center">
              <Typography flex={1} color="white" variant="subtitle2" fontWeight={600}>
                고객 지원
              </Typography>
              <Button href="/inquiry" variant="contained" sx={{ marginLeft: '40px' }} color="secondary">
                <Typography variant="button">1:1 문의하기</Typography>
              </Button>
            </Stack>
            <Typography alignSelf="center" variant="h1" color="white" paddingTop="56px">
              커리어 네비게이터에게 궁금한 점을 검색해주세요!
            </Typography>
            <TextField
              placeholder="검색하기"
              size="small"
              value={searchText}
              onChange={onSearchTextChange}
              sx={{
                width: '100%',
                maxWidth: '500px',
                background: 'white',
                borderRadius: '999px',
                '& fieldset': { border: 'none' },
                alignSelf: 'center',
                marginTop: '24px',
              }}
              InputProps={{
                startAdornment: <Search sx={{ marginRight: '12px' }} />,
                endAdornment: (
                  <Close sx={{ display: searchText ? 'display' : 'none', cursor: 'pointer' }} onClick={onClearClick} />
                ),
              }}
            />
          </Stack>
        </ContentLayout>
      </Stack>
      <ContentLayout>
        <Stack p={7} spacing={6}>
          {announcements && (
            <Link underline="none" href="/announcement">
              <Stack direction="row" spacing={2} alignItems="center">
                <Chip variant="filled" label="공지" color="primary" />
                <Typography>{announcements[0].title}</Typography>
              </Stack>
            </Link>
          )}

          <Divider />
          <Stack direction="row" alignItems="center">
            <Typography variant="h1">{searchText ? '검색 결과' : '자주 묻는 질문'}</Typography>
          </Stack>
          <Grid container rowGap={2}>
            {searchText
              ? searchedContents.map((content) => (
                <Grid item md={6} sm={12}>
                  <Stack spacing={2}>
                    <Card sx={{ padding: '18px', cursor: 'pointer' }} onClick={() => onExpandClick(content)}>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body1">
                          {content.question}
                        </Typography>
                        <ExpandMore
                          sx={{ alignSelf: 'start' }}
                          expand={!!extendedContents?.includes(content)}
                          aria-expanded={!!extendedContents?.includes(content)}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </Stack>
                      <Collapse in={!!extendedContents?.includes(content)}>
                        <Divider sx={{ marginTop: '16px' }} />
                        <Typography variant="body1" paddingTop="16px">
                          {content.answer}
                        </Typography>
                      </Collapse>
                    </Card>
                  </Stack>
                </Grid>
              ))
              : contents?.map((item) => (
                <Grid item md={6} sm={12} sx={{ paddingY: '8px', paddingRight: '16px' }}>
                  <Stack spacing={0}>
                    <Typography variant="h2" paddingBottom={3}>
                      {item.title}
                    </Typography>
                    {item.contents.map((content) => (
                      <Card sx={{ paddingY: '8px', cursor: 'pointer' }} onClick={() => onExpandClick(content)}>
                        <Stack spacing={1}>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="h3">
                              {content.question}
                            </Typography>
                            <ExpandMore
                              sx={{ alignSelf: 'start' }}
                              expand={!!extendedContents?.includes(content)}
                              aria-expanded={!!extendedContents?.includes(content)}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </Stack>
                          <Divider />
                        </Stack>
                        <Collapse in={!!extendedContents?.includes(content)}>
                          <Typography variant="body1" paddingY={2}>
                            {content.answer}
                          </Typography>
                        </Collapse>
                      </Card>
                    ))}
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

export default FaqPage;

import { Chip, Stack, useTheme } from '@mui/material';
import { differenceBy, intersectionBy } from 'lodash';
import { useMemo } from 'react';

import { UserTag } from '../models';

interface Props {
  givenTags: UserTag[];
  myTags: UserTag[];
}

export default function ProfileTags(props: Props) {
  const {
    givenTags, myTags,
  } = props;

  const profileTags = useMemo(() => {
    const commonTags = intersectionBy(givenTags, myTags, 'id');
    const remainingTags = differenceBy(givenTags, myTags, 'id');

    return { common: commonTags, remaining: remainingTags };
  }, [givenTags, myTags]);

  const theme = useTheme();

  return (
    <Stack direction="row" flexWrap="wrap" columnGap={1} rowGap={1}>
      {profileTags.common.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.value}
          variant="filled"
          color="primary"
          sx={{ color: theme.palette.grey[900] }}
        />
      ))}
      {profileTags.remaining.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.value}
          variant="outlined"
          color="default"
        />
      ))}
    </Stack>
  );
}

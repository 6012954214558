import { Box, Container, Stack, Typography, styled, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

function FooterText(text: String) {
  const theme = useTheme();
  return (
    <Typography variant="body2" sx={{ color: theme.palette.common.white }}>
      {text}
    </Typography>
  );
}

const FooterLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.common.white,
  textDecoration: 'none',
}));

export default function FooterView() {
  const theme = useTheme();

  return (
    <Stack
      style={{
        bottom: 0,
        backgroundColor: theme.palette.grey[700],
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Container maxWidth="lg" sx={{ minWidth: theme.breakpoints.values.lg }}>
        <Stack m="48px" sx={{ color: theme.palette.common.white }}>
          <Stack marginBottom={3}>
            <Box marginBottom={2}>
              <Typography variant="body1" sx={{ color: 'inherit', fontWeight: 600 }}>
                커리어몬스터 주식회사
              </Typography>
            </Box>
            {FooterText('대표: 구민규 |  이메일: info@careermonster.co.kr')}
            {FooterText(
              '주소: 부산광역시 수영구 무학로 10, 4층 | 사업자등록번호: 845-87-01965 | 통신판매: 2023-부산해운대-1357 호',
            )}
            {FooterText('고객지원센터: 010-5812-8762')}
          </Stack>
          <Stack direction="row" spacing={3}>
            <FooterLink to="/faq">고객지원</FooterLink>
            <FooterLink to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8458701965" target="_blank">
              사업자정보확인
            </FooterLink>
            <FooterLink to="/terms/information-policy">개인정보처리방침</FooterLink>
            <FooterLink to="/terms/service-policy">이용약관</FooterLink>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}

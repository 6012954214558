import { Search } from '@mui/icons-material';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { debounce, differenceBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { searchCompany } from '../../api/meta';
import { Company } from '../../models';

interface Props extends Pick<AutocompleteProps<Company, false, false, false>, 'size' | 'fullWidth'> {
  industry?: string;
  type?: string;
  company: Company | null;
  excludes?: Company[];
  setCompany: (newValue: Company | null) => void;
  companyName?: string | null;
}

export default function CompanySelectBox(props: Props) {
  const { size, fullWidth, industry = '', type = '', company, setCompany, companyName, excludes } = props;

  const [searchKeyword, setSearchKeyword] = useState<string>();

  const {
    data: companyPages,
    fetchNextPage: fetchNextCompanyPage,
    hasNextPage: hasNextCompanyPage,
  } = useInfiniteQuery({
    queryKey: ['companies', { type, industry }] as [string, { industry: string; type: string }],
    queryFn: async (context) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { type, industry } = context.queryKey[1];

      const ret = await searchCompany({
        type: type === '' ? undefined : type,
        industry: industry === '' ? undefined : industry,
        page: context.pageParam,
        size: 50,
      });

      return ret;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => (lastPage.last ? null : lastPage.number + 1),
  });

  const { data: searchResults, isLoading } = useQuery({
    queryKey: ['company-search', { type, industry, searchKeyword }],
    queryFn: async (context) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { type, industry, searchKeyword } = context.queryKey[1] as {
        type: string;
        industry: string;
        searchKeyword: string | undefined;
      };
      const ret = await searchCompany({
        korName: searchKeyword,
        type: type === '' ? undefined : type,
        industry: industry === '' ? undefined : industry,
        page: 0,
        size: 10,
      });

      return ret.empty ? [] : ret.content;
    },
    enabled: !!searchKeyword,
  });

  const companies = useMemo(() => {
    const original = (searchKeyword ? searchResults : companyPages?.pages.flatMap((e) => (e.empty ? [] : e.content))) ?? [];

    return excludes ? differenceBy(original, excludes, 'id') : original;
  }, [companyPages?.pages, excludes, searchKeyword, searchResults]);

  useEffect(() => {
    if (companyName === undefined) {
      // not used
      return;
    }

    if (!companyName) {
      if (company) {
        setCompany(null);
      }

      return;
    }

    if (company?.korName === companyName) {
      return;
    }

    const found = companies.find((entry) => entry.korName === companyName);
    if (found) {
      setCompany(found);
      return;
    }

    setSearchKeyword(companyName);
  }, [companies, company, companyName, setCompany]);

  const onInputChange: AutocompleteProps<Company, false, false, false>['onInputChange'] = debounce(
    async (e, value, reason) => {
      if (reason === 'reset') {
        setSearchKeyword(undefined);
      } else if (reason === 'clear') {
        setSearchKeyword(undefined);
      } else {
        if (companies.some((entry) => entry.korName.includes(value))) {
          return;
        }

        setSearchKeyword(value);
      }
    },
    800,
  );

  return (
    <Autocomplete
      size={size}
      fullWidth={fullWidth}
      value={company}
      onInputChange={onInputChange}
      onChange={(e, newValue) => {
        setCompany(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.korName === value.korName}
      options={companies}
      getOptionLabel={(option) => option.korName}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="회사명"
          InputProps={{
            ...params.InputProps,
            startAdornment: <Search sx={{ marginRight: '' }} />,
          }}
        />
      )}
      loading={isLoading}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            if (hasNextCompanyPage) {
              fetchNextCompanyPage();
            }
          }
        },
      }}
    />
  );
}

export class ServerError extends Error {
  public code: number;

  constructor(input: { code: number; msg: string; }) {
    const {
      code, msg,
    } = input;
    super(msg);
    this.code = code;
  }
}

export interface ErrorResponse {
  code: string;
  message: string;
  detail: string;
}

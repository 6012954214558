import { Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getPersonalizedRecommendations, getStatusOfUser } from '../../api/user';
import AppContext from '../../AppContext';
import MoreProfile from '../../common/more-profile';
import TopPick from '../../common/top-pick';
import { UserCoverLetterListItem } from '../../models';

export default function PersonalRecommendation() {
  const { customer, userStatus, setUserStatus } = useContext(AppContext);

  const [personalRecommendation, setPersonalRecommendation] = useState<UserCoverLetterListItem>();

  useEffect(() => {
    if (!customer) {
      return;
    }

    (async () => {
      const status = await getStatusOfUser(customer.publicId);

      setUserStatus(status);
    })();

    (async () => {
      const data = await getPersonalizedRecommendations(customer.publicId);
      setPersonalRecommendation(data[0]);
    })();
  }, [customer, setUserStatus]);

  const content = useMemo(() => {
    if (!customer || !userStatus) {
      return <Skeleton height="160px" />;
    }

    if (!userStatus.shouldFillMandatory) {
      if (personalRecommendation) {
        return <TopPick item={personalRecommendation} myTags={customer?.userProfile?.profileTags ?? []} />;
      }
      return <Skeleton height="160px" />;
    }

    return <MoreProfile />;
  }, [customer, personalRecommendation, userStatus]);

  return (
    <Stack spacing={6} alignItems="center">
      <Typography variant="h1">
        {customer?.name}
        님과 유사한 지원자의 합격 자소서!
      </Typography>
      {content}
    </Stack>
  );
}

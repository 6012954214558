/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

import { Customer, UserStatus } from './models';

interface ContextInterface {
  initialized: boolean;
  setInitialized(initialized: boolean): void;
  customer: Customer | undefined;
  setCustomer(user: Customer | undefined): void;
  memberHash: String | undefined;
  setMemberHash(hash: string | undefined): void;
  userStatus: UserStatus | undefined;
  setUserStatus(status: UserStatus): void;
  menuOpen: boolean;
  setMenuOpen(open: boolean): void;
}

export default createContext<ContextInterface>({
  initialized: false,
  setInitialized: (initialized: boolean) => {},
  customer: undefined,
  setCustomer: (u) => {},
  memberHash: undefined,
  setMemberHash: () => {},
  userStatus: undefined,
  setUserStatus: (u) => {},
  menuOpen: false,
  setMenuOpen: () => {},
});

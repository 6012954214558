import { Box, Button, keyframes, Stack, styled, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { searchCompany } from '../../api/meta';
import { addFavoriteCompanies, deleteFavoriteCompanies, getFavoriteCompanies } from '../../api/user';
import AppContext from '../../AppContext';
import { MAX_NUM_OF_FAVORITE_COMPANIES } from '../../common/constant';
import { useCommonSnackbar } from '../../common/hooks';
import CompanySelectBox from '../../common/input/company-select-box';
import { Company, FavoriteCompany } from '../../models';

interface InterestedCompanyContentProps {
  favoriteCompanies: FavoriteCompany[];
  addFavoriteCompany: (company: Company) => Promise<void> | void;
  deleteFavoriteCompany: (favoriteCompany: FavoriteCompany) => Promise<void> | void;
  setError: (message: string) => void;
  displayNothing: boolean;
}

const flow = (translateX: string) => keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(${translateX})
  }
`;

const FlowBox = styled('div')<FlowBoxProps>(
  {
    display: 'flex',
    flexWrap: 'nowrap',
    transform: 'translate3d(0, 0, 0)',
    columnGap: '24px',
  },
  (props) => ({ animation: `${flow(props.translateX)} ${props.time}s linear 0s infinite normal none running` }),
);

interface FlowBoxProps {
  time: number;
  translateX: string;
}

function CompanyFlowBox() {
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      const ret = await searchCompany({
        size: 50,
      });

      return ret.empty ? [] : ret.content;
    },
    initialData: [],
  });

  return (
    <Box overflow="hidden">
      <FlowBox time={companies.length * 3} translateX={`-${96 * companies.length * 1.5}px`}>
        {companies.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`company-${entry.id}-${index}`} textAlign="center">
            <img
              width="96px"
              height="96px"
              style={{
                borderRadius: '100%',
                padding: '4px',
              }}
              src={entry.ciUri}
              alt={entry.korName}
            />
          </Box>
        ))}
      </FlowBox>
    </Box>
  );
}

export function InterestedCompanyContent(props: InterestedCompanyContentProps) {
  const { favoriteCompanies, addFavoriteCompany, deleteFavoriteCompany, setError, displayNothing } = props;

  const [company, setCompany] = useState<Company | null>(null);

  const theme = useTheme();

  const onCompanySelect = async (value: Company | null) => {
    setCompany(value);
    if (!value) {
      return;
    }

    if (favoriteCompanies.length >= MAX_NUM_OF_FAVORITE_COMPANIES) {
      setError(`관심회사는 최대 ${MAX_NUM_OF_FAVORITE_COMPANIES}개까지 등록 가능합니다.`);
      setCompany(null);
      return;
    }

    await addFavoriteCompany(value);

    setCompany(null);
  };

  const deleteCompany = async (favoriteCompany: FavoriteCompany) => {
    await deleteFavoriteCompany(favoriteCompany);
  };

  const excludes = useMemo(() => favoriteCompanies.map((e) => e.company), [favoriteCompanies]);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h2">관심 회사 찾기</Typography>
        <CompanySelectBox
          size="small"
          fullWidth
          company={company}
          excludes={excludes}
          setCompany={onCompanySelect}
        />
        <Typography variant="body2" fontWeight={400}>관심 회사는 최대 5개까지 입력할 수 있어요.</Typography>
      </Stack>

      {favoriteCompanies.length > 0 && (
        <Stack spacing={1}>
          {favoriteCompanies.map((item) => (
            <Stack key={`bookmarked-${item.id}`} direction="row" alignItems="center" spacing={1} width={420}>
              <img
                width="48px"
                height="48px"
                style={{
                  border: `1px solid ${theme.palette.grey[100]}`,
                  objectFit: 'contain',
                  objectPosition: 'center',
                  borderRadius: '100%',
                  padding: '4px',
                }}
                src={item.company.ciUri}
                alt={item.company.korName}
              />
              <Typography flexGrow={1}>{item.company.korName}</Typography>
              <Button onClick={() => deleteCompany(item)} sx={{ color: theme.palette.grey[300] }}>
                삭제
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
      {favoriteCompanies.length === 0 && displayNothing && <CompanyFlowBox />}
    </Stack>
  );
}

export default function InterestedCompany() {
  const { customer } = useContext(AppContext);

  const [favoriteCompanies, setFavoriteCompanies] = useState<FavoriteCompany[]>([]);
  const [snackbar, setFeedback] = useCommonSnackbar();

  useEffect(() => {
    if (!customer) {
      return;
    }

    (async () => {
      const data = await getFavoriteCompanies(customer.publicId);
      if (data.empty) {
        return;
      }

      setFavoriteCompanies(data.content);
    })();
  }, [customer]);

  const addFavoriteCompany = async (company: Company) => {
    if (!customer) {
      return;
    }

    try {
      await addFavoriteCompanies(customer.publicId, [company]);

      const data = await getFavoriteCompanies(customer.publicId);
      if (data.empty) {
        setFavoriteCompanies([]);
        return;
      }

      setFavoriteCompanies(data.content);
      setFeedback({
        level: 'success',
        message: `${company.korName}을 관심회사로 등록했습니다.`,
      });
    } catch (error) {
      setFeedback({
        level: 'error',
        message: '오류가 발생했습니다.',
      });
    }
  };

  const deleteFavoriteCompany = async (favoriteCompany: { id: number; company: Company }) => {
    if (!customer) {
      return;
    }

    await deleteFavoriteCompanies(customer.publicId, favoriteCompany.id);
    setFavoriteCompanies((prev) => prev.filter((item) => item.id !== favoriteCompany.id));
  };

  return (
    <>
      <InterestedCompanyContent
        favoriteCompanies={favoriteCompanies}
        addFavoriteCompany={addFavoriteCompany}
        deleteFavoriteCompany={deleteFavoriteCompany}
        setError={(message) => setFeedback({
          level: 'error',
          message,
        })}
        displayNothing
      />
      {snackbar}
    </>
  );
}

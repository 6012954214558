import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const CommonModalStyle = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '30%',
  // transform: 'translate(-50%, -50%)',
  minWidth: '600px',
  maxWidth: '600px',
  overflow: 'hidden',
};

interface CommonModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string | ReactNode; // ReactNode 타입 추가
}

export default function CommonModal({
  open,
  onClose,
  title,
  content,
}: CommonModalProps) {
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      disableEscapeKeyDown
      onClose={handleClose}
      aria-labelledby="common-modal-title"
      aria-describedby="common-modal-description"
      sx={CommonModalStyle}
    >
      <Box sx={{ bgcolor: '#FFFFFF', p: 4 }}>
        <Stack spacing={2}>
          {title && (
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
          )}
          {typeof content === 'string' ? (
            <Typography variant="body2">
              {content}
            </Typography>
          ) : (
            content
          )}
          <Button variant="outlined" onClick={onClose} color="warning">
            닫기
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

import { Close } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { keys, values } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import AppContext from '../../../AppContext';
import { getCoverLetter, getCoverLetterTemplateList } from '../../../api/cover-letter';
import { postCoverLetter } from '../../../api/user-cover-letter';
import { useCommonSnackbar } from '../../../common/hooks';
import CompanySelectBox from '../../../common/input/company-select-box';
import { Company, CoverLetter, CoverLetterListItem, ServerError } from '../../../models';
import { fromSemesterType } from '../../../util/date-util';
import { CategoryOfCompanies, getCategoryOfCompanies } from '../../../util/meta';
import CoverLetterTemplateCard from './template-card';
import TemplateViewer from './template-viewer';

const PAGE_SIZE = 20;

export default function TemplateSelector() {
  const { customer } = useContext(AppContext);

  const [categoryMap, setCategoryMap] = useState<CategoryOfCompanies>({});
  const [type, setType] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [company, setCompany] = useState<Company | null>(null);

  const [coverLetterTemplates, setCoverLetterTemplates] = useState<CoverLetterListItem[]>([]);
  const [detailCoverLetterItem, setDetailCoverLetterItem] = useState<CoverLetterListItem>();
  const [coverLetter, setCoverLetter] = useState<CoverLetter>();
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const [snackbar, setFeedback] = useCommonSnackbar();

  useEffect(() => {
    if (!detailCoverLetterItem) {
      return;
    }

    getCoverLetter(detailCoverLetterItem.id).then((cv) => {
      setCoverLetter(cv);
    });
  }, [detailCoverLetterItem]);

  useEffect(() => {
    setPage(0);
  }, [company, industry, type]);

  useEffect(() => {
    (async () => {
      const map = await getCategoryOfCompanies();

      setCategoryMap(map);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getCoverLetterTemplateList({
        type: type === '' ? undefined : type,
        industry: industry === '' ? undefined : industry,
        company: company == null ? undefined : company.korName,
        page,
        size: PAGE_SIZE,
      });

      setCoverLetterTemplates((prev) => (page === 0 ? [] : prev).concat(data.empty ? [] : data.content));

      setTotalElements(data.totalElements);
    })();
  }, [company, industry, type, page]);

  const navigate = useNavigate();
  const theme = useTheme();

  const onIndustryChange = (e: SelectChangeEvent) => {
    setIndustry(e.target.value);
    setType('');
  };

  const onTypeChange = (e: SelectChangeEvent) => {
    setType(e.target.value);
  };

  const handleSelectTemplate = async () => {
    if (!customer || !coverLetter) {
      return;
    }

    try {
      setIsProcessing(true);
      const newCoverLetter = await postCoverLetter(customer.publicId, {
        recruitmentId: coverLetter.recruitment.id,
        coverLetterId: coverLetter.id,
        answers: coverLetter.questions.map((question) => ({ questionId: question.id, content: '' })),
        editingStatus: 'DONE',
      });

      setFeedback({
        level: 'success',
        message: `${newCoverLetter.recruitment.company.korName} 자소서 생성에 성공했습니다.`,
      });
      setTimeout(() => navigate(`/my-cover-letter/${newCoverLetter.id}`), 2000);
    } catch (error) {
      setIsProcessing(false);
      if (error instanceof ServerError) {
        if (error.code === 409) {
          setFeedback({ level: 'error', message: '중복된 자기소개서가 있습니다.' });
        } else {
          setFeedback({ level: 'error', message: '알 수 없는 오류가 발생했습니다.' });
        }
      } else {
        setFeedback({ level: 'error', message: '알 수 없는 오류가 발생했습니다.' });
      }
    }
  };

  const industries = useMemo(() => keys(categoryMap), [categoryMap]);
  const types = useMemo(
    () => (industry === '' ? values(categoryMap).flat(1) : categoryMap[industry]),
    [industry, categoryMap],
  );

  return (
    <Stack>
      <Stack direction="row" spacing={2} alignItems="center" marginBottom={4}>
        <Typography variant="body1">업계</Typography>
        <FormControl sx={{ minWidth: 120 }}>
          <Select value={industry} onChange={onIndustryChange} displayEmpty size="small">
            <MenuItem value="">전체</MenuItem>
            {industries.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body1" paddingLeft="8px">
          업종
        </Typography>
        <FormControl sx={{ minWidth: 120 }}>
          <Select value={type} onChange={onTypeChange} displayEmpty size="small">
            <MenuItem value="">전체</MenuItem>
            {types.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="body1" paddingLeft="8px">
          회사명
        </Typography>
        <FormControl sx={{ minWidth: 240 }}>
          <CompanySelectBox
            size="small"
            fullWidth
            industry={industry}
            type={type}
            company={company}
            setCompany={setCompany}
          />
        </FormControl>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <InfiniteScroll
          dataLength={coverLetterTemplates.length}
          next={() => {
            setPage(page + 1);
          }}
          hasMore={coverLetterTemplates.length < totalElements}
          loader={(
            <Box display="flex" paddingY={4} justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          scrollThreshold={0.9}
          hasChildren
        >
          <Grid container spacing={4}>
            {coverLetterTemplates.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={`g-item-${item.id}`}
                onClick={() => {
                  setDetailCoverLetterItem(item);
                }}
              >
                <CoverLetterTemplateCard
                  logo={item.recruitment.company.ciUri}
                  company={item.recruitment.company.korName}
                  year={item.recruitment.year}
                  semester={fromSemesterType(item.recruitment.semester)}
                  employeeType={item.recruitment.employeeType}
                  separation={item.recruitment.separation}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
      <Dialog
        open={!!detailCoverLetterItem}
        onClose={() => {
          setDetailCoverLetterItem(undefined);
        }}
        maxWidth="md"
        fullWidth
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            color: theme.palette.grey[900],
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setDetailCoverLetterItem(undefined);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="contained" disabled={isProcessing} onClick={handleSelectTemplate}>
              선택
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Stack padding="16px 32px">
            {coverLetter ? <TemplateViewer coverLetter={coverLetter} /> : <Skeleton height="50vh" />}
          </Stack>
        </DialogContent>
      </Dialog>

      {snackbar}
    </Stack>
  );
}

import { Button, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { getMyCoverLetters } from '../../api/user';
import AppContext from '../../AppContext';
import { ContentLayout, MainLayout } from '../../common/style';
import { UserCoverLetterListItem } from '../../models';
import CoverLetterTable, { getCommonColumnDefs } from '../cover-letter/cover-letter-table';

export default function MyCoverLetterPage() {
  const { customer } = useContext(AppContext);
  const [userCoverLetters, setUserCoverLetters] = useState<UserCoverLetterListItem[]>([]);

  useEffect(() => {
    if (!customer) {
      return;
    }

    getMyCoverLetters(customer.publicId).then((data) => {
      if (data.empty) {
        return;
      }

      setUserCoverLetters(data.content);
    });
  }, [customer]);

  return (
    <MainLayout>
      <ContentLayout>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" paddingBottom={7}>
            <Typography variant="subtitle2" flex={1}>
              내 자소서
            </Typography>
            <Button variant="contained" href="/my-cover-letter/new">
              새로운 자소서 쓰기
            </Button>
          </Stack>
          <CoverLetterTable
            columnDefs={getCommonColumnDefs()}
            rows={userCoverLetters}
            onRowClick={(row) => {
              window.open(`/my-cover-letter/${row.id}`, '_blank', 'noreferrer');
            }}
          />
        </Stack>
      </ContentLayout>
    </MainLayout>
  );
}

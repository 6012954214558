import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppContext from '../AppContext';
import AnnouncementPage from '../pages/announcement';
import CoverLetterPage from '../pages/cover-letter';
import CoverLetterDetailPage from '../pages/cover-letter/detail';
import FavoriteCoverLetterPage from '../pages/cover-letter/favorites';
import EmailValidationView from '../pages/email-validation';
import FaqPage from '../pages/faq';
import HomePage from '../pages/home';
import InquiryPage from '../pages/inquiry';
import HomeView from '../pages/landing';
import LoginView from '../pages/login';
import MyCoverLetterPage from '../pages/my-cover-letter';
import MyCoverLetterDetailPage from '../pages/my-cover-letter/detail';
import NewCoverLetter from '../pages/my-cover-letter/new';
import SettingHome from '../pages/my-page';
import PasswordResetView from '../pages/password/password-reset';
import PickPage from '../pages/pick';
import RecommendationPage from '../pages/recommendation';
import RegistrationView from '../pages/registration';
import InformationPolicyView from '../pages/terms/information-policy';
import ServicePolicyView from '../pages/terms/service-policy';
import PassPage from '../pages/pass';
import PlanComparisonTable from '../pages/pass/plan';

export default function Navigation() {
  const { customer } = useContext(AppContext);

  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/email-validation" element={<EmailValidationView />} />
      <Route path="/login" element={<LoginView />} />
      <Route path="/registration" element={<RegistrationView />} />
      <Route path="/terms/information-policy" element={<InformationPolicyView />} />
      <Route path="/terms/service-policy" element={<ServicePolicyView />} />
      <Route path="/password/reset" element={<PasswordResetView />} />
      <Route path="/inquiry" element={<InquiryPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/announcement" element={<AnnouncementPage />} />

      {customer && (
        <>
          <Route path="/recommendation" element={<RecommendationPage />} />
          <Route path="/pick" element={<PickPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/my-page" element={<SettingHome />} />
          <Route path="/my-page/:tab" element={<SettingHome />} />
          <Route path="/passed" element={<CoverLetterPage />} />
          <Route path="/favorites" element={<FavoriteCoverLetterPage />} />
          <Route path="/cover-letter/:coverLetterId" element={<CoverLetterDetailPage />} />
          <Route path="/my-cover-letter" element={<MyCoverLetterPage />} />
          <Route path="/my-cover-letter/:myCoverLetterId" element={<MyCoverLetterDetailPage />} />
          <Route path="/my-cover-letter/new" element={<NewCoverLetter />} />
          <Route path="/pass" element={<PassPage />} />
          <Route path="/plan" element={<PlanComparisonTable />} />
        </>
      )}
      <Route path="/*" element={<HomeView />} />
    </Routes>
  );
}
